@charset "UTF-8";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

main, article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

em {
  font-style: italic; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-clip: padding-box !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

button {
  border-style: none;
  cursor: pointer;
  padding: 0; }
  button:focus {
    outline: none; }

/* DEBUG */
/* LAYOUT */
/* MEDIA QUERIES */
/* COLORI */
/* FONT */
/*Manage FUOC*/
/*Aggiungere qui le classi di eventuali elementi da ritardare */
.fuoc {
  opacity: 0;
  transition: opacity 0.2s ease; }

body.fuoc-loaded .fuoc {
  opacity: 1; }

/*Wordpress Admin Bar Fixes*/
body.admin-bar .landing.with-news {
  height: calc(100% - 112px); }

body.admin-bar .landing {
  height: calc(100% - 72px); }

@font-face {
  font-family: 'HK Grotesk';
  src: url("../font/hkgrotesk-medium.woff2") format("woff2"), url("../font/hkgrotesk-medium.woff") format("woff"), url("../font/hkgrotesk-medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'HK Grotesk';
  src: url("../font/hkgrotesk-regular.woff2") format("woff2"), url("../font/hkgrotesk-regular.woff") format("woff"), url("../font/hkgrotesk-regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

.typed-fade-out {
  animation: .3s ease forwards fadeOut; }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0); }
  40% {
    transform: translateY(-16px); }
  60% {
    transform: translateY(-8px); } }

@keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0) rotate(-90deg); }
  40% {
    transform: translateX(-16px) rotate(-90deg); }
  60% {
    transform: translateX(-8px) rotate(-90deg); } }

.bounce {
  animation: bounce 2s infinite; }

.bounce-right {
  animation: bounceRight 2s infinite; }

body {
  font-family: "HK Grotesk", sans-serif, Helvetica, Arial, sans-serif;
  color: #616367;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px; }
  @media only screen and (min-width: 4000px) {
    body {
      font-size: 1.125vw;
      line-height: 1.5vw; } }

a,
a:visited {
  text-decoration: none;
  color: inherit; }

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #296FAC;
  margin: 0.8em 0 0.4em; }

h1 {
  font-size: 36px;
  line-height: 38px;
  font-weight: 500; }
  @media only screen and (min-width: 4000px) {
    h1 {
      font-size: 2.25vw;
      line-height: 2.8125vw; } }

h2 {
  font-size: 34px;
  font-weight: 500;
  line-height: 36px; }
  @media only screen and (max-width: 480px) {
    h2 {
      font-size: 28px;
      line-height: 30px; } }
  @media only screen and (min-width: 4000px) {
    h2 {
      font-size: 2.125vw;
      line-height: 2.5vw; } }

h3 {
  font-size: 28px;
  font-weight: 500;
  line-height: 32px; }
  @media only screen and (max-width: 480px) {
    h3 {
      font-size: 24px;
      line-height: 26px; } }
  @media only screen and (min-width: 4000px) {
    h3 {
      font-size: 1.75vw;
      line-height: 2.25vw; } }

h4 {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px; }
  @media only screen and (min-width: 4000px) {
    h4 {
      font-size: 1.25vw;
      line-height: 1.75vw; } }

.separator {
  padding: 20px 0;
  margin-top: 30px;
  border-top: 1px solid rgba(0, 158, 224, 0.4); }

a.button, form input[type="submit"] {
  text-align: center;
  display: inline-block;
  color: #296FAC;
  height: 40px;
  min-width: 220px;
  border: 1px solid #0061C4;
  border-radius: 100px;
  box-shadow: 0 0 20px 2px rgba(41, 111, 172, 0.2);
  padding: 0 20px;
  padding: 0 20px;
  line-height: 40px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: all .4s ease; }
  a.button:after, form input[type="submit"]:after {
    content: "";
    display: block;
    position: absolute;
    height: 200%;
    width: 100%;
    background-color: #0061C4;
    z-index: -1;
    bottom: 100%;
    left: 0;
    will-change: transform;
    transition: all .4s ease; }
  a.button:before, form input[type="submit"]:before {
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: white;
    z-index: -1; }
  a.button:hover, form input[type="submit"]:hover {
    background: transparent;
    color: white;
    border-color: white; }
    a.button:hover:after, form input[type="submit"]:hover:after {
      transform: translateY(55%); }
  @media only screen and (min-width: 4000px) {
    a.button, form input[type="submit"] {
      height: 2.5vw;
      line-height: 2.5vw;
      min-width: 13.75vw;
      box-shadow: 0 0 1.25vw 0.3125vw rgba(41, 111, 172, 0.2);
      padding: 0 1.25vw; } }

p {
  margin: 10px 0 15px; }
  @media only screen and (min-width: 4000px) {
    p {
      margin: .625vw 0 .9375vw; } }

.button-container {
  margin-top: 60px; }
  @media only screen and (max-width: 960px) {
    .button-container {
      margin-top: 40px;
      text-align: center; } }
  @media only screen and (max-width: 768px) {
    .button-container {
      margin-top: 20px; } }
  @media only screen and (min-width: 4000px) {
    .button-container {
      margin-top: 3.75vw; } }
  .button-container-centered {
    text-align: center; }

a.cta-link {
  font-weight: 500;
  line-height: 40px;
  color: #009EE0; }
  @media only screen and (min-width: 4000px) {
    a.cta-link {
      line-height: 2.5vw; } }
  a.cta-link:hover {
    border-bottom: 2px solid rgba(0, 158, 224, 0.4); }

.quiet {
  opacity: .7; }

.show-on-medium {
  display: none; }
  @media only screen and (max-width: 960px) {
    .show-on-medium {
      display: inline; } }

.entry-content {
  margin-bottom: 40px; }
  .entry-content-servizio {
    width: 100%;
    font-size: 20px;
    line-height: 28px;
    color: #296FAC;
    font-weight: 500; }
  .entry-content img {
    max-width: 100%;
    height: 100%; }
  .entry-content ul {
    list-style-type: disc;
    padding-left: 1em;
    margin: 1em 0 2em; }
    .entry-content ul li {
      line-height: 1.5; }
  .entry-content ol {
    list-style-type: decimal;
    padding-left: 1em;
    margin: 1em 0 2em; }
    .entry-content ol li {
      line-height: 1.5; }
  .entry-content table {
    margin: 30px 0; }
    .entry-content table thead,
    .entry-content table tfoot {
      color: white;
      background-color: #296FAC;
      border: 1px solid white;
      text-align: left; }
      .entry-content table thead th,
      .entry-content table tfoot th {
        padding: 5px; }
    .entry-content table tr {
      background-color: rgba(0, 158, 224, 0.1);
      border: 1px solid white; }
      .entry-content table tr:nth-child(even) {
        background-color: #fff; }
    .entry-content table td {
      padding: 5px; }
  @media only screen and (min-width: 4000px) {
    .entry-content {
      margin-bottom: 2.5vw; }
      .entry-content table {
        margin: 1.875vw; }
        .entry-content table thead th,
        .entry-content table tfoot th {
          padding: .3125vw; }
        .entry-content table td {
          padding: .3125vw; } }

span.arrow {
  height: 1.5em;
  width: 1em;
  padding: 0 10px;
  background: url("../img/arrow.svg") center center no-repeat;
  background-size: contain;
  display: inline-block;
  vertical-align: middle; }
  span.arrow.rotate {
    transform: rotate(-90deg);
    margin: 0 10px; }

.titolo {
  font-size: 36px;
  font-weight: 500;
  line-height: 40px; }
  @media only screen and (max-width: 960px) {
    .titolo {
      font-size: 30px;
      line-height: 34px; } }
  @media only screen and (max-width: 768px) {
    .titolo {
      font-size: 26px;
      line-height: 29px; } }
  @media only screen and (max-width: 480px) {
    .titolo {
      font-size: 22px;
      line-height: 25px; } }

.breadcrumbs {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  color: #757575; }
  .breadcrumbs span.arrow {
    height: 1em;
    margin: 0 2px 4px; }
  .breadcrumbs a {
    color: #009EE0; }
  .breadcrumbs + h1 {
    margin-top: 0; }

/* GRID */
body, html {
  height: 100%; }

@media only screen and (max-width: 768px) {
  #trigger {
    padding-top: 107px; } }

.wrapper {
  position: relative;
  padding-right: 100px;
  padding-left: 100px;
  max-width: 1240px;
  margin: 0 auto;
  pointer-events: none; }
  .wrapper:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .wrapper * {
    pointer-events: all; }
  @media only screen and (max-width: 768px) {
    .wrapper {
      padding-right: 20px;
      padding-left: 20px; } }
  @media only screen and (max-width: 480px) {
    .wrapper {
      padding-right: 15px;
      padding-left: 15px; } }
  @media only screen and (min-width: 4000px) {
    .wrapper {
      max-width: 77.5vw;
      padding-right: 6.25vw;
      padding-left: 6.25vw; } }

.col-wrapper {
  padding-bottom: 50px;
  padding-top: 50px;
  margin-bottom: 50px; }
  .col-wrapper:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .col-wrapper .text-col {
    width: calc(50% - 20px);
    float: left; }
    @media only screen and (max-width: 768px) {
      .col-wrapper .text-col {
        float: none;
        width: 100%; } }
    @media only screen and (min-width: 4000px) {
      .col-wrapper .text-col {
        width: calc(50% - 1.25vw); } }
    .col-wrapper .text-col + .text-col {
      margin-left: 40px; }
  .col-wrapper .half-col {
    float: left;
    width: 50%;
    margin-left: 20px; }
    @media only screen and (max-width: 768px) {
      .col-wrapper .half-col {
        float: none;
        height: auto;
        width: 100%;
        margin: 0; } }
  @media only screen and (min-width: 4000px) {
    .col-wrapper {
      padding-bottom: 3.125vw;
      padding-top: 3.125vw;
      margin-bottom: 3.125vw; }
      .col-wrapper .text-col + .text-col {
        margin-left: 2.5vw; }
      .col-wrapper .half-col {
        height: 12.5vw;
        margin-left: 1.25vw; } }

.flex-container {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 50px;
  padding-top: 25px; }
  .flex-container > div {
    flex: 0 0 45%; }
    .flex-container > div:nth-child(odd) {
      margin-right: 10%; }
    @media only screen and (max-width: 768px) {
      .flex-container > div {
        flex: 0 0 100%; }
        .flex-container > div:nth-child(odd) {
          margin-right: 0; } }
  @media only screen and (min-width: 4000px) {
    .flex-container {
      padding-bottom: 3.125vw;
      padding-top: 1.5625vw; } }

.main-text-container {
  max-width: 80ch; }
  .main-text-container--article {
    margin: 40px 0; }

.thumbnail-bg {
  height: 300px;
  background-position: center;
  background-size: cover;
  margin: 20px 0 40px; }
  @media only screen and (min-width: 4000px) {
    .thumbnail-bg {
      height: 18.75vw;
      margin: 1.25vw 0 2.5vw; } }

.vertical-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  pointer-events: all; }

.checker {
  width: 20px;
  height: 20px;
  position: relative; }
  .checker input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0; }
  .checker span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    .checker span.checked:before {
      opacity: 1; }
    .checker span:before {
      content: "";
      font: 16px/100% "icons";
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none !important;
      text-indent: 0;
      width: 18px;
      text-align: center;
      position: absolute;
      top: 1px;
      left: 0;
      opacity: 0;
      transition: opacity .5s ease; }

form:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

form .form-group:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

form .form-group .input-group {
  width: 100%;
  position: relative;
  line-height: 1.8;
  margin-bottom: 20px; }
  form .form-group .input-group.half-width {
    float: left;
    width: 48%; }
    form .form-group .input-group.half-width + .half-width {
      margin-left: 4%; }
    @media only screen and (max-width: 768px) {
      form .form-group .input-group.half-width {
        float: none;
        width: 100%; }
        form .form-group .input-group.half-width + .half-width {
          margin-left: 0; } }
  form .form-group .input-group input {
    width: 100%;
    display: block;
    font-size: 22px;
    line-height: 40px;
    height: 40px;
    padding: 5px;
    border-style: none;
    border-width: 0;
    border-bottom: 2px solid #ccc;
    margin-bottom: 5px;
    background: #f9f9f9;
    font-family: "HK Grotesk", sans-serif; }
    form .form-group .input-group input:focus {
      outline: none;
      border-bottom-color: #296FAC; }
    form .form-group .input-group input.invalid {
      border-bottom-color: #D0393F; }
      form .form-group .input-group input.invalid:focus {
        border-bottom-color: #296FAC; }
    form .form-group .input-group input.valid {
      border-bottom-color: #28A94F; }
      form .form-group .input-group input.valid:focus {
        border-bottom-color: #296FAC; }
    form .form-group .input-group input::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: #ccc;
      font-size: 18px; }
    form .form-group .input-group input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #ccc;
      font-size: 18px;
      opacity: 1; }
    form .form-group .input-group input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #ccc;
      font-size: 18px;
      opacity: 1; }
    form .form-group .input-group input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #ccc;
      font-size: 18px; }
    form .form-group .input-group input::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #ccc;
      font-size: 18px; }
    form .form-group .input-group input[type="checkbox"] {
      display: inline;
      width: auto;
      height: auto; }
    form .form-group .input-group input[type="radio"] {
      display: inline;
      width: auto;
      height: auto; }
  form .form-group .input-group label {
    font-size: 18px;
    font-weight: 400;
    padding-left: 0;
    color: rgba(0, 0, 0, 0.8); }
  form .form-group .input-group .wpcf7-list-item.first {
    margin: 0; }
  form .form-group .input-group .wpcf7-list-item label {
    white-space: nowrap;
    display: inline;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    position: relative;
    cursor: pointer; }
    @media only screen and (max-width: 768px) {
      form .form-group .input-group .wpcf7-list-item label {
        white-space: normal; } }
  form .form-group .input-group select {
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* remove default arrow */
    background: #f9f9f9 url("../img/freccia-select.svg") center right no-repeat;
    padding: 5px 25px 5px 5px;
    border: none;
    font-size: 22px;
    font-family: "HK Grotesk", sans-serif;
    border-style: none;
    border-width: 0;
    border-bottom: 2px solid #ccc;
    positon: relative; }
    form .form-group .input-group select::-ms-expand {
      display: none;
      /* hide the default arrow in ie10 and ie11 */ }
    form .form-group .input-group select:focus {
      outline: none;
      border-bottom-color: #296FAC; }
  form .form-group .input-group textarea {
    background: #f9f9f9;
    padding: 5px;
    outline: none;
    border-style: none;
    border-width: 0;
    border-bottom: 2px solid #ccc;
    font-family: "HK Grotesk", sans-serif;
    font-size: 18px;
    width: 100%; }
    form .form-group .input-group textarea::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: #ccc;
      font-size: 18px; }
    form .form-group .input-group textarea:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #ccc;
      font-size: 18px;
      opacity: 1; }
    form .form-group .input-group textarea::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #ccc;
      font-size: 18px;
      opacity: 1; }
    form .form-group .input-group textarea:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #ccc;
      font-size: 18px; }
    form .form-group .input-group textarea::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #ccc;
      font-size: 18px; }
    form .form-group .input-group textarea:focus {
      border-bottom-color: #296FAC; }

form .drop_uploader.drop_zone .text {
  font-family: inherit;
  font-size: 20px;
  color: #0077ac; }

form .submit-container {
  text-align: center;
  margin: 30px 0; }

form input[type="checkbox"], form input[type="checkbox"] {
  cursor: pointer; }

form input[type="submit"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  font-family: "HK Grotesk", sans-serif; }
  @media only screen and (max-width: 768px) {
    form input[type="submit"] {
      font-size: 16px; } }
  form input[type="submit"]:hover {
    background-color: #296FAC;
    color: white; }
  form input[type="submit"]:disabled {
    border-color: #757575;
    color: #757575;
    opacity: .3; }

/*Contact Form styles*/
div.wpcf7 {
  margin: 0;
  padding: 0; }

div.wpcf7-response-output {
  margin: 2em 0.5em 1em;
  padding: 0.2em 1em; }

div.wpcf7 .screen-reader-response {
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  width: 1px;
  margin: 0;
  padding: 0;
  border: 0; }

div.wpcf7-mail-sent-ok {
  background-color: rgba(57, 143, 20, 0.3);
  font-weight: 500;
  padding: 8px;
  text-align: center;
  border-radius: 10px; }

div.wpcf7-mail-sent-ng {
  border: 2px solid #ff0000; }

div.wpcf7-spam-blocked {
  border: 2px solid #ffa500; }

div.wpcf7-validation-errors {
  background: rgba(255, 0, 0, 0.2);
  font-weight: 500;
  padding: 8px;
  text-align: center;
  border-radius: 10px; }

.wpcf7-form-control-wrap {
  position: relative; }

span.wpcf7-not-valid-tip {
  color: #f00;
  font-size: 1em;
  font-weight: normal;
  display: block; }

.use-floating-validation-tip span.wpcf7-not-valid-tip {
  position: absolute;
  top: 20%;
  left: 20%;
  z-index: 100;
  border: 1px solid #ff0000;
  background: #fff;
  padding: .2em .8em; }

span.wpcf7-list-item {
  display: inline-block;
  margin: 0 0 0 1em; }

span.wpcf7-list-item-label::before,
span.wpcf7-list-item-label::after {
  content: " "; }

.wpcf7-display-none {
  display: none; }

div.wpcf7 .ajax-loader {
  visibility: hidden;
  display: inline-block;
  background-image: url("../../images/ajax-loader.gif");
  width: 16px;
  height: 16px;
  border: none;
  padding: 0;
  margin: 0 0 0 4px;
  vertical-align: middle; }

div.wpcf7 .ajax-loader.is-active {
  visibility: visible; }

div.wpcf7 div.ajax-error {
  display: none; }

div.wpcf7 .placeheld {
  color: #888; }

div.wpcf7 .wpcf7-recaptcha iframe {
  margin-bottom: 0; }

div.wpcf7 input[type="file"] {
  cursor: pointer; }

div.wpcf7 input[type="file"]:disabled {
  cursor: default; }

div.wpcf7 .wpcf7-submit {
  background: #fff; }

/*Conditional Fields styles*/
/* initially hide all groups (even before JS is loaded), so the page will never render them while loading */
[data-class="wpcf7cf_group"] {
  display: none; }

/*Drop Uploader styles*/
.drop_uploader.drop_zone {
  width: 100%;
  min-height: 100px;
  text-align: center;
  border: 1px dashed #616367;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  margin: 10px 0; }
  .drop_uploader.drop_zone a {
    text-align: center;
    display: inline-block;
    color: #296FAC;
    height: 40px;
    min-width: 220px;
    border: 1px solid #0061C4;
    border-radius: 100px;
    box-shadow: 0 0 20px 2px rgba(41, 111, 172, 0.2);
    padding: 0 20px;
    line-height: 40px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: all .4s ease;
    vertical-align: middle; }
    .drop_uploader.drop_zone a:after {
      content: "";
      display: block;
      position: absolute;
      height: 200%;
      width: 100%;
      background-color: #0061C4;
      z-index: -1;
      bottom: 100%;
      left: 0;
      will-change: transform;
      transition: all .4s ease; }
    .drop_uploader.drop_zone a:before {
      content: "";
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: white;
      z-index: -1; }
    .drop_uploader.drop_zone a:hover {
      background: transparent;
      color: white;
      border-color: white; }
      .drop_uploader.drop_zone a:hover:after {
        transform: translateY(55%); }
    @media only screen and (min-width: 4000px) {
      .drop_uploader.drop_zone a {
        height: 2.5vw;
        line-height: 2.5vw;
        min-width: 13.75vw;
        box-shadow: 0 0 1.25vw 0.3125vw rgba(41, 111, 172, 0.2);
        padding: 0 1.25vw; } }

.drop_uploader.drop_zone.hover {
  border: 3px dashed #4a90e2; }

.drop_uploader.drop_zone .text_wrapper {
  margin-top: 30px; }

.drop_uploader.drop_zone .text_wrapper i {
  font-size: 50px;
  color: #616367;
  position: relative;
  top: 14px;
  margin-right: 25px; }

.drop_uploader.drop_zone .text {
  font-size: 24px;
  color: #616367;
  font-weight: 500; }

.drop_uploader.drop_zone ul.files {
  width: 90%;
  margin: 0 auto;
  text-align: left;
  list-style: none;
  margin: 10px auto 35px; }

.drop_uploader.drop_zone ul.files li {
  font-size: 18px;
  color: #616367;
  background-color: #f4f4f4;
  border-top: 2px solid #f4f4f4;
  border-bottom: 2px solid #f4f4f4;
  margin: 0;
  padding: 5px; }

.drop_uploader.drop_zone ul.files li i.action-delete {
  color: #ff0000;
  float: right;
  cursor: pointer; }

.drop_uploader.drop_zone ul.files.thumb {
  width: 90%;
  text-align: left;
  list-style: none;
  margin-bottom: 35px;
  overflow: auto; }

.drop_uploader.drop_zone ul.files.thumb li {
  min-width: 100px;
  display: block;
  float: left;
  font-size: 18px;
  color: #616367;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 5px 15px;
  text-align: center; }

.drop_uploader.drop_zone ul.files.thumb li div.thumbnail {
  width: 100px;
  height: 100px;
  border-radius: 8px 8px 8px 8px;
  -moz-border-radius: 8px 8px 8px 8px;
  -webkit-border-radius: 8px 8px 8px 8px;
  background-size: cover;
  margin: 0 auto;
  border: 1px solid #616367; }

.drop_uploader.drop_zone ul.files.thumb li div.thumbnail .du_hover_layer {
  width: 98px;
  height: 98px;
  border-radius: 7px;
  background: #fff;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 0.1s ease-in;
  -moz-transition: opacity 0.1s ease-in;
  transition: opacity 0.1s ease-in; }

.drop_uploader.drop_zone ul.files.thumb li div.thumbnail:hover .du_hover_layer {
  opacity: .8;
  position: absolute;
  -webkit-transition: opacity 0.1s ease-in;
  -moz-transition: opacity 0.1s ease-in;
  transition: opacity 0.1s ease-in; }

.drop_uploader.drop_zone ul.files li .du_progress {
  display: inline-block;
  float: right;
  margin: 4px 9px; }

.drop_uploader.drop_zone ul.files.thumb li .du_progress {
  width: 0px;
  height: 0px;
  margin-top: -100px;
  position: relative;
  right: 75px; }

.drop_uploader.drop_zone ul.files.thumb li .du_progress canvas {
  margin-top: 25px; }

.drop_uploader.drop_zone ul.files.thumb li div.thumbnail i {
  font-size: 32px;
  position: relative;
  top: calc(50% - 16px);
  display: block;
  color: #999999; }

.drop_uploader.drop_zone ul.files.thumb li div.thumbnail.rotate_90 {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg); }

.drop_uploader.drop_zone ul.files.thumb li div.thumbnail.rotate_180 {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg); }

.drop_uploader.drop_zone ul.files.thumb li div.thumbnail.rotate_270 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg); }

.drop_uploader.drop_zone ul.files.thumb.ajax li div.thumbnail:hover i {
  visibility: hidden;
  opacity: 0;
  display: none; }

.drop_uploader.drop_zone ul.files.thumb li div.thumbnail i.action-delete {
  color: #D0393F;
  visibility: hidden;
  opacity: 0;
  float: none;
  -webkit-transition: opacity 0.1s ease-in;
  -moz-transition: opacity 0.1s ease-in;
  transition: opacity 0.1s ease-in; }

.drop_uploader.drop_zone ul.files.thumb li div.thumbnail:hover i.action-delete {
  visibility: visible;
  opacity: 1;
  display: block;
  cursor: pointer;
  -webkit-transition: opacity 0.1s ease-in;
  -moz-transition: opacity 0.1s ease-in;
  transition: opacity 0.1s ease-in; }

.drop_uploader.drop_zone ul.files.thumb li span.title {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block; }

.drop_uploader.drop_zone ul.files li:nth-child(even) {
  background-color: transparent; }

.drop_uploader.drop_zone ul.files li i {
  font-size: 20px;
  position: relative;
  top: 2px;
  margin: 0px 10px; }

.drop_uploader.drop_zone .errors p {
  color: #D0393F; }

.drop_uploader.drop_zone input[type=file] {
  display: none;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)"; }

.row:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

header.main-header {
  height: auto;
  position: fixed;
  top: 0;
  width: 100%;
  transition: all .4s ease; }
  header.main-header .wrapper {
    padding: 20px; }
    header.main-header .wrapper:after {
      content: ".";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0; }
    header.main-header .wrapper .logo {
      height: 47px;
      width: 47px;
      margin-left: 20px;
      float: left;
      transform: rotate(-7deg); }
      header.main-header .wrapper .logo svg {
        max-height: 100%;
        max-width: 100%; }
      @media only screen and (max-width: 768px) {
        header.main-header .wrapper .logo {
          margin-left: 0; } }
    header.main-header .wrapper .logo-title {
      display: none;
      font-size: 36px;
      font-weight: 300;
      line-height: 45px;
      color: black;
      margin-left: 10px; }
      @media only screen and (max-width: 768px) {
        header.main-header .wrapper .logo-title {
          display: inline;
          font-size: 0; } }
      header.main-header .wrapper .logo-title svg {
        vertical-align: middle;
        height: 21px;
        width: 70px; }
    @media only screen and (max-width: 768px) {
      header.main-header .wrapper {
        padding: 20px 10px; } }
  @media only screen and (max-width: 768px) {
    header.main-header {
      background-color: white;
      z-index: 9999; }
      header.main-header.header-scrolled {
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08); } }
  @media only screen and (min-width: 4000px) {
    header.main-header .wrapper {
      padding: 1.25vw; }
      header.main-header .wrapper .logo {
        height: 2.9375vw;
        width: 2.9375vw;
        margin-left: 1.25vw; }
      header.main-header .wrapper .logo-title {
        font-size: 2.25vw;
        line-height: 2.8125vw;
        margin-left: 0.625vw; } }

.menu-opened .branding,
.menu-opened .logo,
.menu-opened .logo-title svg {
  display: none !important; }

a.menu-toggle {
  float: right;
  color: #296FAC;
  font-size: 16px;
  font-weight: 400;
  line-height: 47px; }
  a.menu-toggle div.hamburger {
    height: 2px;
    width: 24px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    background-color: #296FAC; }
    a.menu-toggle div.hamburger:before {
      content: "";
      display: block;
      position: absolute;
      top: -6px;
      height: 2px;
      background-color: #296FAC;
      width: 100%; }
    a.menu-toggle div.hamburger:after {
      content: "";
      display: block;
      position: absolute;
      height: 2px;
      bottom: -6px;
      background-color: #296FAC;
      width: 100%; }
  @media only screen and (min-width: 4000px) {
    a.menu-toggle {
      font-size: 1vw;
      line-height: 2.8125vw; } }

.navbar-overlay {
  overflow: scroll;
  padding-bottom: 80px;
  padding-top: 87px;
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 97, 196, 0.95);
  color: white;
  z-index: 99999; }
  @media only screen and (min-width: 4000px) {
    .navbar-overlay {
      padding-bottom: 5vw;
      padding-top: 5.4375vw; } }
  .navbar-overlay header {
    height: 47px;
    line-height: 47px;
    margin: 20px 0;
    font-size: 36px;
    position: absolute;
    top: 0;
    width: 100%; }
    .navbar-overlay header .wrapper {
      padding-right: 20px; }
      @media only screen and (max-width: 768px) {
        .navbar-overlay header .wrapper {
          padding: 0 20px; } }
      .navbar-overlay header .wrapper .logo-title {
        font-size: 0;
        font-weight: 300;
        line-height: 45px;
        color: black;
        margin: 0; }
        @media only screen and (max-width: 768px) {
          .navbar-overlay header .wrapper .logo-title {
            display: inline;
            font-size: 0; } }
        .navbar-overlay header .wrapper .logo-title svg {
          display: inline !important;
          margin: -15px 0 0 0;
          vertical-align: middle;
          height: 21px;
          width: 70px;
          fill: white; }
          @media only screen and (min-width: 4000px) {
            .navbar-overlay header .wrapper .logo-title svg {
              height: 1.3125vw;
              width: 4.375vw; } }
          .navbar-overlay header .wrapper .logo-title svg g {
            fill: white; }
    .navbar-overlay header .navbar-toggle {
      font-size: 16px;
      font-weight: 400;
      line-height: 47px;
      float: right;
      padding-right: 23px;
      position: relative; }
      .navbar-overlay header .navbar-toggle:after {
        content: "";
        display: block;
        position: absolute;
        height: 2px;
        width: 20px;
        background-color: #fff;
        transform: rotate(45deg);
        right: 0;
        top: 22px; }
      .navbar-overlay header .navbar-toggle:before {
        content: "";
        display: block;
        position: absolute;
        height: 2px;
        width: 20px;
        background-color: #fff;
        transform: rotate(-45deg);
        right: 0;
        top: 22px; }
    @media only screen and (min-width: 4000px) {
      .navbar-overlay header {
        height: 2.8125vw;
        line-height: 2.8125vw;
        font-size: 2.25vw;
        margin: 1.25vw 0; }
        .navbar-overlay header .wrapper {
          padding-right: 1.25vw; }
          .navbar-overlay header .wrapper .navbar-toggle {
            font-size: 1vw;
            line-height: 2.8125vw;
            padding-right: 1.4375vw; }
            .navbar-overlay header .wrapper .navbar-toggle:after, .navbar-overlay header .wrapper .navbar-toggle:before {
              top: 1.375vw; } }
  .navbar-overlay nav {
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    padding-top: 20px;
    overflow: scroll;
    height: 100%; }
    @media only screen and (max-width: 768px) {
      .navbar-overlay nav .wrapper {
        padding: 0 20px; } }
    .navbar-overlay nav ul li {
      font-size: 30px;
      font-weight: 500;
      line-height: 1.7;
      padding-top: 8px;
      padding-bottom: 8px; }
      .navbar-overlay nav ul li a:hover {
        border-bottom: 2px solid rgba(255, 255, 255, 0.4); }
      @media only screen and (max-width: 768px) {
        .navbar-overlay nav ul li {
          font-size: 20px;
          line-height: 23px; } }
      .navbar-overlay nav ul li ul {
        padding-top: 8px; }
        .navbar-overlay nav ul li ul li {
          padding-left: 30px; }
          .navbar-overlay nav ul li ul li:last-child {
            padding-bottom: 0; }
  .navbar-overlay footer {
    position: absolute;
    bottom: 0;
    height: 60px;
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.3); }
    .navbar-overlay footer ul {
      float: left; }
      .navbar-overlay footer ul li {
        display: inline-block;
        font-size: 20px;
        font-weight: 500;
        line-height: 80px;
        margin-right: 45px; }
        @media only screen and (max-width: 768px) {
          .navbar-overlay footer ul li {
            font-size: 14px;
            margin-right: 20px; }
            .navbar-overlay footer ul li:last-child {
              display: none; } }
        .navbar-overlay footer ul li a:hover {
          border-bottom: 2px solid rgba(255, 255, 255, 0.4); }
      .navbar-overlay footer ul.credits {
        float: right;
        position: absolute;
        right: 22px; }
        .navbar-overlay footer ul.credits li {
          margin: 0;
          display: block; }
  @media only screen and (min-width: 4000px) {
    .navbar-overlay nav {
      padding-top: 1.25vw; }
      .navbar-overlay nav ul li {
        font-size: 1.875vw; }
        .navbar-overlay nav ul li ul li {
          padding-left: 1.875vw; }
    .navbar-overlay footer {
      height: 5vw; }
      .navbar-overlay footer ul li {
        font-size: 1.25vw;
        line-height: 5vw;
        margin-right: 2.8125vw; } }

footer.main-footer {
  padding: 24px 0; }
  footer.main-footer .footer-left {
    float: left;
    font-size: 15px;
    line-height: 20px; }
    @media only screen and (max-width: 480px) {
      footer.main-footer .footer-left {
        float: none;
        margin-bottom: 15px;
        text-align: center; } }
  footer.main-footer .footer-right {
    float: right;
    padding: 20px 0; }
    @media only screen and (max-width: 768px) {
      footer.main-footer .footer-right {
        padding: 0; } }
    @media only screen and (max-width: 480px) {
      footer.main-footer .footer-right {
        float: none;
        text-align: center; } }
    footer.main-footer .footer-right ul li {
      display: inline-block;
      font-size: 15px;
      font-weight: 500;
      color: #296FAC; }
      @media only screen and (max-width: 480px) {
        footer.main-footer .footer-right ul li {
          text-align: left;
          padding-right: 12px; } }
      footer.main-footer .footer-right ul li + li {
        margin-left: 30px; }
        @media only screen and (max-width: 768px) {
          footer.main-footer .footer-right ul li + li {
            margin-left: 0; } }
    @media only screen and (min-width: 4000px) {
      footer.main-footer .footer-right {
        padding: 1.875vw 0; }
        footer.main-footer .footer-right .footer-left {
          font-size: 0.9375vw;
          line-height: 1.25vw; }
        footer.main-footer .footer-right .footer-right {
          padding: 1.25vw 0; }
          footer.main-footer .footer-right .footer-right ul li {
            font-size: 0.9375vw; }
            footer.main-footer .footer-right .footer-right ul li + li {
              margin-left: 1.875vw; } }

.landing {
  position: relative; }
  .landing:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .landing .text-container {
    width: calc(50% - 20px);
    float: left;
    height: 100%; }
    @media only screen and (max-width: 768px) {
      .landing .text-container {
        float: none;
        width: 100%; } }
    @media only screen and (min-width: 4000px) {
      .landing .text-container {
        width: calc(50% - 1.25vw); } }
    .landing .text-container:after {
      content: ".";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0; }
    .landing .text-container header {
      height: 47px;
      margin: 20px 0;
      padding-right: 40px;
      position: relative;
      z-index: 1; }
      .landing .text-container header h1 {
        margin: 0;
        font-weight: 300;
        color: black;
        visibility: hidden; }
      .landing .text-container header .menu-toggle {
        position: absolute;
        right: 40px;
        top: 0;
        color: #296FAC;
        font-size: 16px;
        font-weight: 400; }
      @media only screen and (max-width: 768px) {
        .landing .text-container header {
          display: none; } }
      @media only screen and (min-width: 4000px) {
        .landing .text-container header {
          height: 2.8125vw;
          line-height: 2.8125vw;
          padding-right: 2.5vw;
          margin: 1.25vw 0; }
          .landing .text-container header a {
            font-size: 1vw; } }
    .landing .text-container .servizi {
      position: absolute;
      top: 87px;
      bottom: 0;
      width: 50%; }
      @media only screen and (max-width: 768px) {
        .landing .text-container .servizi {
          width: 100%;
          position: relative;
          top: auto;
          bottom: auto;
          min-height: 260px; } }
      .landing .text-container .servizi .more-link {
        position: absolute;
        bottom: 50px; }
        @media only screen and (max-width: 960px) {
          .landing .text-container .servizi .more-link {
            bottom: 0; } }
        @media only screen and (max-width: 768px) {
          .landing .text-container .servizi .more-link {
            left: 47px;
            bottom: 20px; } }
        .landing .text-container .servizi .more-link .arrow {
          animation: bounce 2s infinite; }
      .landing .text-container .servizi-viewbox {
        padding-right: 100px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%; }
        @media only screen and (max-width: 960px) {
          .landing .text-container .servizi-viewbox {
            padding-right: 50px; } }
        @media only screen and (max-width: 768px) {
          .landing .text-container .servizi-viewbox {
            padding-right: 0;
            padding: 0 47px;
            position: relative;
            top: auto;
            transform: none;
            width: 100%; } }
        @media only screen and (max-width: 480px) {
          .landing .text-container .servizi-viewbox {
            padding-right: 0; } }
        .landing .text-container .servizi-viewbox__singolo {
          display: none; }
        .landing .text-container .servizi-viewbox h2 {
          font-size: 34px;
          font-weight: 500;
          line-height: 36px;
          display: inline-block;
          opacity: 0;
          white-space: pre-line; }
          @media only screen and (max-width: 768px) {
            .landing .text-container .servizi-viewbox h2 {
              font-size: 28px;
              line-height: 34px;
              margin-bottom: 0; } }
        .landing .text-container .servizi-viewbox p {
          color: #757575;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px; }
        .landing .text-container .servizi-viewbox #typedHeaders,
        .landing .text-container .servizi-viewbox #typedPars {
          display: none; }
      @media only screen and (min-width: 4000px) {
        .landing .text-container .servizi {
          top: 5.4375 vw; }
          .landing .text-container .servizi a {
            bottom: 3.125vw; }
          .landing .text-container .servizi-viewbox {
            padding-right: 6.25vw; }
            .landing .text-container .servizi-viewbox h2 {
              font-size: 2.125vw;
              line-height: 2.5vw;
              min-height: 2.5vw; }
            .landing .text-container .servizi-viewbox p {
              font-size: 1vw;
              line-height: 1.375vw; } }
  .landing .video-container {
    width: calc(50% + 100px);
    float: right;
    margin-right: -80px;
    background-color: black;
    padding-bottom: calc(50% + 100px);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3);
    overflow: hidden;
    position: relative; }
    @media only screen and (max-width: 768px) {
      .landing .video-container {
        float: none;
        width: calc(100% + 30px);
        margin-left: -15px;
        margin-right: 0; } }
    @media only screen and (min-width: 4000px) {
      .landing .video-container {
        width: calc(50% + 6.25vw);
        margin-right: -5vw; } }
    @media only screen and (max-width: 768px) {
      .landing .video-container {
        float: none;
        width: calc(100% + 30px);
        margin-left: -15px;
        margin-right: 0;
        padding-bottom: 100%; } }
    .landing .video-container .video-overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      background: url("../img/placeholder-square.jpg") center center no-repeat;
      background-size: cover;
      display: none;
      z-index: 1; }
      @media only screen and (max-width: 768px) {
        .landing .video-container .video-overlay {
          display: block; }
          .landing .video-container .video-overlay #play {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 45px;
            width: 45px;
            background-color: white;
            border-radius: 50%; }
            .landing .video-container .video-overlay #play:after {
              content: "";
              display: block;
              position: absolute;
              left: 16px;
              top: 12px;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 10px 0 10px 17.32px;
              border-color: transparent transparent transparent #009ee0; } }
      .landing .video-container .video-overlay .play {
        position: absolute;
        cursor: pointer;
        height: 2; }
    .landing .video-container .video-cropper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      padding-left: 177.78%; }
    .landing .video-container iframe {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }

.payoff {
  padding: 100px 0;
  text-align: center;
  background: #f9f9f9 url("../img/texture.png") center center repeat;
  position: relative;
  z-index: -1; }
  .payoff:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 10%;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
    /* IE6-9 */ }
  .payoff:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 10%;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100 */
    background: -moz-linear-gradient(top, white 0%, rgba(255, 255, 255, 0) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, white 0%, rgba(255, 255, 255, 0) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, white 0%, rgba(255, 255, 255, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=0);
    /* IE6-9 */ }
  .payoff .wrapper {
    overflow-x: initial; }
  .payoff h3 {
    font-size: 28px;
    font-weight: 400;
    line-height: 36px;
    margin: 1em 0 2em; }
    @media only screen and (max-width: 960px) {
      .payoff h3 {
        font-size: 22px;
        line-height: 30px; } }
    @media only screen and (max-width: 768px) {
      .payoff h3 {
        font-size: 18px;
        line-height: 24px; } }
    @media only screen and (max-width: 480px) {
      .payoff h3 {
        font-size: 16px;
        line-height: 20px; } }
  @media only screen and (max-width: 768px) {
    .payoff {
      padding: 50px 0; } }
  @media only screen and (max-width: 480px) {
    .payoff {
      padding: 40px 0; } }
  @media only screen and (min-width: 4000px) {
    .payoff {
      padding: 6.25vw 0; }
      .payoff h2 {
        font-size: 2.25vw;
        line-height: 2.8125vw; }
      .payoff h3 {
        font-size: 1.75vw;
        line-height: 2.375vw; } }
  .payoff .button-container {
    display: inline-block;
    margin: 10px 0 0;
    position: relative;
    line-height: 1; }
    .payoff .button-container a.button, .payoff .button-container form input[type="submit"], form .payoff .button-container input[type="submit"] {
      margin: 0 10px 0; }
    .payoff .button-container span.counter {
      position: absolute;
      display: block;
      top: 0;
      left: auto;
      right: 10px;
      height: 25px;
      width: 25px;
      background-color: #f44336;
      line-height: 25px;
      text-align: center;
      border-radius: 50%;
      z-index: 99;
      transform: translateY(-33%);
      color: white;
      font-weight: 600;
      font-size: 14px; }
  .payoff-servizi {
    background: none;
    padding: 100px 0 40px; }
    @media only screen and (max-width: 768px) {
      .payoff-servizi {
        padding: 50px 0 20px; } }
    @media only screen and (max-width: 480px) {
      .payoff-servizi {
        padding: 40px 0 20px; } }
    @media only screen and (min-width: 4000px) {
      .payoff-servizi {
        padding: 6.25vw 0 2.5vw; } }

.wrapper.wrapper-servizi {
  padding-top: 20px; }

.servizio {
  height: 425px;
  margin-bottom: 70px; }
  .servizio:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  @media only screen and (max-width: 768px) {
    .servizio {
      height: auto;
      margin-bottom: 50px; } }
  .servizio .text-container h2 {
    font-size: 34px;
    font-weight: 500;
    line-height: 36px; }
    @media only screen and (min-width: 4000px) {
      .servizio .text-container h2 {
        font-size: 2.125vw;
        line-height: 2.5vw; } }
    @media only screen and (max-width: 768px) {
      .servizio .text-container h2 {
        font-size: 30px;
        line-height: 33px; } }
  .servizio .text-container p {
    color: #757575;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 50px; }
    @media only screen and (min-width: 4000px) {
      .servizio .text-container p {
        font-size: 1vw;
        line-height: 1.375vw;
        margin-bottom: 3.125vw; } }
    @media only screen and (max-width: 768px) {
      .servizio .text-container p {
        margin-bottom: 20px; } }
  .servizio .img-container {
    height: 100%;
    background: url("../img/bg1.jpg") center center;
    background-size: cover;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3);
    position: relative; }
    @media only screen and (max-width: 768px) {
      .servizio .img-container {
        height: 300px; } }
    .servizio .img-container .blue-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #296FAC; }
  .servizio.img-destra .text-container {
    padding: 68px 40px 0 0;
    width: calc(50% - 20px);
    float: left; }
    @media only screen and (max-width: 768px) {
      .servizio.img-destra .text-container {
        float: none;
        width: 100%; } }
    @media only screen and (min-width: 4000px) {
      .servizio.img-destra .text-container {
        width: calc(50% - 1.25vw); } }
    @media only screen and (min-width: 4000px) {
      .servizio.img-destra .text-container {
        padding: 4.25vw 2.5vw 0 0; } }
    @media only screen and (max-width: 768px) {
      .servizio.img-destra .text-container {
        padding: 20px 0; } }
  .servizio.img-destra .img-container {
    width: calc(50% + 100px);
    float: right;
    margin-right: -80px; }
    @media only screen and (max-width: 768px) {
      .servizio.img-destra .img-container {
        float: none;
        width: calc(100% + 30px);
        margin-left: -15px;
        margin-right: 0; } }
    @media only screen and (min-width: 4000px) {
      .servizio.img-destra .img-container {
        width: calc(50% + 6.25vw);
        margin-right: -5vw; } }
    .servizio.img-destra .img-container .blue-overlay {
      transform-origin: center left; }
  .servizio.img-sinistra .text-container {
    padding: 68px 0 0 40px;
    width: calc(50% - 20px);
    float: right; }
    @media only screen and (max-width: 768px) {
      .servizio.img-sinistra .text-container {
        float: none;
        width: 100%; } }
    @media only screen and (min-width: 4000px) {
      .servizio.img-sinistra .text-container {
        width: calc(50% - 1.25vw); } }
    @media only screen and (min-width: 4000px) {
      .servizio.img-sinistra .text-container {
        padding: 4.25vw 0 0 2.5vw; } }
    @media only screen and (max-width: 768px) {
      .servizio.img-sinistra .text-container {
        padding: 30px 0; } }
  .servizio.img-sinistra .img-container {
    width: calc(50% + 100px);
    float: left;
    margin-left: -80px; }
    @media only screen and (max-width: 768px) {
      .servizio.img-sinistra .img-container {
        float: none;
        width: calc(100% + 30px);
        margin-left: -15px;
        margin-right: 0; } }
    @media only screen and (min-width: 4000px) {
      .servizio.img-sinistra .img-container {
        width: calc(50% + 6.25vw);
        margin-left: -5vw; } }
    .servizio.img-sinistra .img-container .blue-overlay {
      transform-origin: center right; }
  @media only screen and (min-width: 4000px) {
    .servizio {
      height: 26.5625vw;
      margin-bottom: 4.375vw; } }

#scrollLockWrap {
  width: 100%;
  height: calc(100% + 2400px);
  position: relative;
  pointer-events: none; }

#scrollLockCont {
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  text-align: center;
  overflow: hidden;
  padding-top: 30px; }
  @media only screen and (min-width: 4000px) {
    #scrollLockCont {
      padding-top: 1.875vw; } }
  #scrollLockCont.fixed {
    position: fixed; }
  #scrollLockCont.fixed-bottom {
    position: absolute;
    bottom: 0;
    top: auto; }
  @media only screen and (max-width: 768px) {
    #scrollLockCont {
      padding-top: 110px; } }
  #scrollLockCont .logo-center {
    margin: 0 auto;
    width: 400px;
    position: relative; }
    @media only screen and (min-width: 4000px) {
      #scrollLockCont .logo-center {
        width: 25vw; } }
    @media only screen and (max-width: 480px) {
      #scrollLockCont .logo-center {
        width: 85%;
        max-width: 300px; } }
  #scrollLockCont #livello1 {
    display: block;
    margin: 0 auto;
    transform: rotate(-7deg); }
    #scrollLockCont #livello1 path {
      stroke: #296FAC;
      stroke-dasharray: 265px;
      stroke-dashoffset: 265px; }
  #scrollLockCont #livello2 {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    margin: 0 auto;
    z-index: -1;
    transform: rotate(-7deg); }
    #scrollLockCont #livello2 path {
      stroke: rgba(191, 231, 247, 0.5); }
  #scrollLockCont h4 {
    text-transform: uppercase;
    color: #296FAC;
    font-weight: 400;
    margin-bottom: 0;
    white-space: nowrap; }
    @media only screen and (max-width: 768px) {
      #scrollLockCont h4 {
        font-size: 14px; } }
  #scrollLockCont .sliding-text-container {
    width: 800%;
    transform: translateY(12.5%); }
    #scrollLockCont .sliding-text-container:after {
      content: ".";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0; }
    #scrollLockCont .sliding-text-container h2 {
      width: 12.5%;
      float: left;
      font-size: 34px;
      color: #757575;
      font-weight: 300;
      line-height: 40px;
      text-align: center;
      margin: 0; }
      @media only screen and (min-width: 4000px) {
        #scrollLockCont .sliding-text-container h2 {
          font-size: 2.125vw;
          line-height: 2.5vw; } }
      @media only screen and (max-width: 768px) {
        #scrollLockCont .sliding-text-container h2 {
          padding: 0px;
          margin: 0; } }
      @media only screen and (max-width: 480px) {
        #scrollLockCont .sliding-text-container h2 {
          font-size: 18px;
          line-height: 22px; } }

section.launch {
  text-align: center;
  padding: 20px 24% 22px;
  color: #296FAC; }
  @media only screen and (min-width: 4000px) {
    section.launch {
      padding: 2.5vw 20% 5vw; } }
  @media only screen and (max-width: 960px) {
    section.launch {
      padding: 20px 30px; } }
  section.launch h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 27px;
    margin: 0; }
    @media only screen and (max-width: 480px) {
      section.launch h2 {
        font-size: 20px;
        margin: 0; } }
  section.launch p {
    font-size: 18px;
    line-height: 23px; }
    @media only screen and (min-width: 4000px) {
      section.launch p {
        font-size: 1.25vw;
        line-height: 1.75vw; } }
    @media only screen and (max-width: 480px) {
      section.launch p {
        font-size: 14px;
        line-height: 20px; } }
  section.launch .info-box {
    display: inline-block; }
    @media only screen and (max-width: 768px) {
      section.launch .info-box {
        display: block; } }
    section.launch .info-box + .info-box {
      margin-left: 40px; }
      @media only screen and (min-width: 4000px) {
        section.launch .info-box + .info-box {
          margin-left: 2.5vw; } }
      @media only screen and (max-width: 768px) {
        section.launch .info-box + .info-box {
          margin-left: 0;
          margin-top: 8px; } }
    section.launch .info-box h4 {
      text-align: left;
      font-size: 14px;
      color: white;
      text-transform: uppercase;
      margin-top: 0; }
      @media only screen and (min-width: 4000px) {
        section.launch .info-box h4 {
          font-size: .9375vw; } }
      @media only screen and (max-width: 768px) {
        section.launch .info-box h4 {
          text-align: center;
          font-size: 13px; } }
    section.launch .info-box h5 {
      text-align: left;
      font-size: 24px;
      font-weight: 500;
      margin: 0; }
      @media only screen and (min-width: 4000px) {
        section.launch .info-box h5 {
          font-size: 1.75vw; } }
      @media only screen and (max-width: 768px) {
        section.launch .info-box h5 {
          text-align: center;
          font-size: 22px; } }
      section.launch .info-box h5 a:hover {
        border-bottom: 2px solid rgba(255, 255, 255, 0.4); }
  section.launch.azzurro {
    background-color: #BFE7F7; }
    section.launch.azzurro a.button:before, section.launch.azzurro form input[type="submit"]:before, form section.launch.azzurro input[type="submit"]:before {
      background-color: transparent; }
    section.launch.azzurro a.button:hover, section.launch.azzurro form input[type="submit"]:hover, form section.launch.azzurro input[type="submit"]:hover {
      color: #296FAC;
      border-color: #296FAC; }
    section.launch.azzurro a.button:after, section.launch.azzurro form input[type="submit"]:after, form section.launch.azzurro input[type="submit"]:after {
      background-color: white; }
  section.launch.celeste {
    background-color: #7FCEEF; }
    section.launch.celeste a.button, section.launch.celeste form input[type="submit"], form section.launch.celeste input[type="submit"] {
      background-color: #7FCEEF; }
      section.launch.celeste a.button:after, section.launch.celeste form input[type="submit"]:after, form section.launch.celeste input[type="submit"]:after {
        background-color: #7FCEEF; }
  section.launch.blu {
    color: white;
    background-color: #009EE0; }
    section.launch.blu h2 {
      color: white; }
    section.launch.blu a.button, section.launch.blu form input[type="submit"], form section.launch.blu input[type="submit"] {
      border-color: white;
      color: white; }
      section.launch.blu a.button:before, section.launch.blu form input[type="submit"]:before, form section.launch.blu input[type="submit"]:before {
        background-color: transparent; }
      section.launch.blu a.button:after, section.launch.blu form input[type="submit"]:after, form section.launch.blu input[type="submit"]:after {
        background-color: white; }
      section.launch.blu a.button:hover, section.launch.blu form input[type="submit"]:hover, form section.launch.blu input[type="submit"]:hover {
        border-color: #296FAC;
        color: #296FAC; }
  section.launch a.button, section.launch form input[type="submit"], form section.launch input[type="submit"] {
    font-size: 15px;
    min-width: 180px;
    font-weight: 500;
    height: 36px;
    line-height: 34px; }

.cookie-notice-container #cn-accept-cookie {
  min-width: 80px;
  vertical-align: middle; }

.cookie-notice-container #cn-more-info {
  height: auto;
  min-width: 0;
  display: inline;
  padding: 0;
  border: none;
  background-color: transparent;
  color: white;
  text-decoration: underline;
  line-height: inherit; }
  .cookie-notice-container #cn-more-info:before, .cookie-notice-container #cn-more-info:after {
    background-color: transparent; }

.branding {
  margin: 20px 0;
  padding-top: 40px; }
  .branding a.link-box {
    width: 70px;
    display: block; }
    @media only screen and (min-width: 4000px) {
      .branding a.link-box {
        width: 4.375vw; } }
  .branding h1 {
    font-size: 0;
    line-height: 0; }
  .branding h2 {
    margin: 0;
    font-weight: 300;
    color: #000;
    font-size: 0;
    line-height: 0; }
  .branding h4.subtitle {
    line-height: 1;
    margin-top: 0;
    font-size: 16px; }
  .branding svg {
    height: 21px;
    width: 70px; }
  @media only screen and (min-width: 4000px) {
    .branding {
      line-height: 2.8125vw;
      margin: 1.25vw 0; }
      .branding svg {
        height: 1.3125vw;
        width: 4.375vw; }
      .branding h4.subtitle {
        font-size: 1vw; } }
  @media only screen and (max-width: 768px) {
    .branding {
      display: none; } }

.intro-container h4 {
  font-size: 20px;
  line-height: 28px;
  color: #296FAC;
  margin: 1em 0; }
  .intro-container h4:first-of-type {
    margin-top: 2em; }

.intro-container .video-sn {
  padding-bottom: 56.25%;
  position: relative; }
  .intro-container .video-sn iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }

@media only screen and (min-width: 4000px) {
  .intro-container h4 {
    font-size: 1.25vw;
    line-height: 1.75vw; } }

#svgContainer {
  margin: 0 auto; }
  #svgContainer .orange {
    fill: #e6550d; }

.entry-content a:not(.button):not(.cta-link):not(.file_browse) {
  color: #009EE0;
  border-bottom: 1px dotted #009EE0; }

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto; }

.half-col h2 {
  margin: 3px 0 40px 0; }

.posizioni-aperte {
  display: flex;
  flex-wrap: wrap; }
  .posizioni-aperte:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .posizioni-aperte .posizione {
    margin-bottom: 40px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    float: left;
    flex: 0 0 30%;
    margin-left: 5%; }
    .posizioni-aperte .posizione:nth-child(3n+1) {
      margin-left: 0; }
    @media only screen and (max-width: 1280px) {
      .posizioni-aperte .posizione {
        flex: 0 0 45%;
        margin-left: 0; }
        .posizioni-aperte .posizione:nth-child(even) {
          margin-left: 10%; } }
    @media only screen and (max-width: 960px) {
      .posizioni-aperte .posizione {
        flex: 0 0 100%; }
        .posizioni-aperte .posizione:nth-child(even) {
          margin-left: 0; } }

.post-container {
  padding: 30px 0; }
  .post-container .button-container {
    text-align: center; }

.center-align-text {
  text-align: center; }

.sharing-widget {
  margin: 2em 0; }

.at-icon-wrapper.at-share-btn.at-svc-linkedin {
  margin-left: 30px;
  margin-right: 30px; }

.at-mobile .at-icon-wrapper.at-share-btn.at-svc-linkedin {
  margin-left: 20px;
  margin-right: 20px; }
