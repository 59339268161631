footer.main-footer {
  padding: 24px 0;
  .footer-left {
    float: left;
    font-size: 15px;
    line-height: 20px;
    @include xs-down {
      float: none;
      margin-bottom: 15px;
      text-align: center
    }
  }
  .footer-right {
    float: right;
    padding: 20px 0;
    @include s-down {
      padding: 0;
    }
    @include xs-down {
      float: none;
      text-align: center
    }
    ul {
      li {
        display: inline-block;
        font-size: 15px;
        font-weight: 500;
        color: $main-blue;
        @include s-down {
          //display: block;
          //text-align: right;
        }
        @include xs-down {
          text-align: left;
          padding-right: 12px
        }
        &+li {
          margin-left: 30px;
          @include s-down {
            margin-left: 0;
          }
        }
      }
    }
  @include xxl-up {
    padding: 1.875vw 0;
    .footer-left {
      font-size: 0.9375vw;
      line-height: 1.25vw;
    }
    .footer-right {
      padding: 1.25vw 0;
      ul li {
        font-size: 0.9375vw;
        &+li {
          margin-left: 1.875vw;
        }
      }
    }
  }
}
}