.post-container {
  padding: 30px 0;
  .button-container {
    text-align: center;
  }
}

.center-align-text {
  text-align: center;
}
.sharing-widget {
  margin: 2em 0;
}

.at-icon-wrapper.at-share-btn.at-svc-linkedin {
  margin-left: 30px;
  margin-right: 30px;
}

.at-mobile .at-icon-wrapper.at-share-btn.at-svc-linkedin {
  margin-left: 20px;
  margin-right: 20px;
}