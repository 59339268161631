@function rem($unit) {
  @return #{$unit/16px}rem;
}

/*Manage FUOC*/ 
/*Aggiungere qui le classi di eventuali elementi da ritardare */

.fuoc {
  opacity: 0;
  transition: opacity 0.2s ease;
}

body.fuoc-loaded {
  .fuoc {
    opacity: 1;
  }
}

/*Wordpress Admin Bar Fixes*/
body.admin-bar {
  .landing.with-news {
    height: calc(100% - 112px);
  }
  .landing {
    height: calc(100% - 72px);
  }
}