header.main-header {
	height: auto;
	position: fixed;
	top: 0;
	width: 100%;
	transition: all .4s ease;
	.wrapper {
		padding: 20px;
		@include clearfix;
		.logo {
			height: 47px;
			width: 47px;
			margin-left: 20px;
			float: left;
			transform: rotate(-7deg);
			svg {
				max-height: 100%;
				max-width: 100%;
			}
			@include s-down {
				margin-left: 0;
			}
		}
		.logo-title {
			display: none;
			font-size: 36px;
			font-weight: 300;
			line-height: 45px;
			color: black;
			margin-left: 10px;
			@include s-down {
				display: inline;
				font-size: 0;
			}
			svg {
				vertical-align: middle;
				height: 21px;
				width: 70px;
			}
		}
		@include s-down {
			padding: 20px 10px;
		}
	}
	@include s-down {
		background-color: white;
		z-index: 9999;
		&.header-scrolled {
			box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
		}
	}
	@include xxl-up {
		.wrapper {
			padding: 1.25vw;
			.logo {
				height: 2.9375vw;
				width: 2.9375vw;
				margin-left: 1.25vw;
			}
			.logo-title {
				font-size: 2.25vw;
				line-height: 2.8125vw;
				margin-left: 0.625vw;
			}
		}
	}
}

.menu-opened {
	.branding,
	.logo,
	.logo-title svg {
		display: none !important;
	}
}

a.menu-toggle {
	float: right;
	color: $main-blue;
	font-size: 16px;
	font-weight: 400;
	line-height: 47px;
	div.hamburger {
		height: 2px;
		width: 24px;
		display: inline-block;
		vertical-align: middle;
		position: relative;
		background-color: $main-blue;
		&:before {
			@include pseudo;
			top: -6px;
			height: 2px;
			background-color: $main-blue;
			width: 100%;
		}
		&:after {
			@include pseudo;
			height: 2px;
			bottom: -6px;
			background-color: $main-blue;
			width: 100%;
		}
	}
	@include xxl-up {
		font-size: 1vw;
		line-height: 2.8125vw;
	}
}

.navbar-overlay {
	overflow: scroll;
	padding-bottom: 80px;
	padding-top: 87px;
	@include xxl-up {
		padding-bottom: 5vw;
		padding-top: 5.4375vw;
	}
	display: none;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#0061C4, .95);
	color: white;
	z-index: 99999;
	header {
		height: 47px;
		line-height: 47px;
		margin: 20px 0;
		font-size: 36px;
		position: absolute;
		top: 0;
		width: 100%;
		.wrapper {
			padding-right: 20px;
			@include s-down {
				padding: 0 20px;
			}
			.logo-title {
				font-size: 0;
				font-weight: 300;
				line-height: 45px;
				color: black;
				margin: 0;
				@include s-down {
					display: inline;
					font-size: 0;
				}
				svg {
					display: inline !important;
					margin: -15px 0 0 0;
					vertical-align: middle;
					height: 21px;
					width: 70px;
					@include xxl-up {
						height: 1.3125vw;
						width: 4.375vw;
					}
					fill: white;
					g {
						fill: white;
					}
				}
			}
		}
		.navbar-toggle {
			font-size: 16px;
			font-weight: 400;
			line-height: 47px;
			float: right;
			padding-right: 23px;
			position: relative;
			&:after {
				@include pseudo;
				height: 2px;
				width: 20px;
				background-color: #fff;
				transform: rotate(45deg);
				right: 0;
				top: 22px;
			}
			&:before {
				@include pseudo;
				height: 2px;
				width: 20px;
				background-color: #fff;
				transform: rotate(-45deg);
				right: 0;
				top: 22px;
			}
		}
		@include xxl-up {
			height: 2.8125vw;
			line-height: 2.8125vw;
			font-size: 2.25vw;
			margin: 1.25vw 0;
			.wrapper {
				padding-right: 1.25vw;
				.navbar-toggle {
					font-size: 1vw;
					line-height: 2.8125vw;
					padding-right: 1.4375vw;
					&:after,
					&:before {
						top: 1.375vw;
					}
				}
			}
		}
	}
	nav {
		border-top: 1px solid rgba(255, 255, 255, .4);
		padding-top: 20px;
		overflow: scroll;
		height: 100%;
		.wrapper {
			@include s-down {
				padding: 0 20px;
			}
		}
		ul li {
			font-size: 30px;
			font-weight: 500;
			line-height: 1.7;
			padding-top: 8px;
			padding-bottom: 8px;
			a:hover {
				border-bottom: 2px solid rgba(#fff, .4)
			}
			@include s-down {
				font-size: 20px;
				line-height: 23px;
			}
			ul {
				padding-top: 8px;
				li {
					padding-left: 30px;
					&:last-child {
						padding-bottom: 0;
					}
				}
			}
		}
	}
	footer {
		position: absolute;
		bottom: 0;
		height: 60px;
		width: 100%;
		border-top: 1px solid rgba(255, 255, 255, .3);
		
		ul {
			float: left;
			li {
			display: inline-block;
			font-size: 20px;
			font-weight: 500;
			line-height: 80px;
			margin-right: 45px;
			@include s-down {
				font-size: 14px;
				margin-right: 20px;
				&:last-child {
					display: none;
				}
			}
			a:hover {
				border-bottom: 2px solid rgba(#fff, .4)
			}
			}
			&.credits {
				float: right;
				position: absolute;
				right: 22px;
				li {
					margin: 0;
					display: block;
				}
			}
		}
		
	}
	@include xxl-up {
		nav {
			padding-top: 1.25vw;
			ul li {
				font-size: 1.875vw;
				ul li {
					padding-left: 1.875vw;
				}
			}
		}
		footer {
			height: 5vw;
			ul li {
				font-size: 1.25vw;
				line-height: 5vw;
				margin-right: 2.8125vw;
			}
		}
	}
}