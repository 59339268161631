.branding {
  margin: 20px 0;
  padding-top: 40px;
  a.link-box {
    width: 70px;
    display: block;
    @include xxl-up {
      width: 4.375vw;
    }
  }
  h1 {
    font-size: 0;
    line-height: 0;
  }
  h2 {
    margin: 0;
    font-weight: 300;
    color: #000;
    font-size: 0;
    line-height: 0;
  }
  h4.subtitle {
    line-height: 1;
    margin-top: 0;
    font-size: 16px;
  }
  svg {
    height: 21px;
    width: 70px;
  }
  @include xxl-up {
    line-height: 2.8125vw;
    margin: 1.25vw 0;
    svg {
      height: 1.3125vw;
      width: 4.375vw;
    }
    h4.subtitle {
      font-size: 1vw;
    }
  }
  @include s-down {
    display: none;
  }
}

.intro-container {

  
  h4 {
    font-size: 20px;
    line-height: 28px;
    color: $main-blue;
    margin: 1em 0;
    &:first-of-type {
      margin-top: 2em;
    }
  }
  .video-sn {
    padding-bottom: 56.25%;
    position: relative;
    iframe {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  @include xxl-up {
    h4 {
      font-size: 1.25vw;
      line-height: 1.75vw; 
    }
  }

}

#svgContainer {
  margin: 0 auto;
  .orange {
    fill: rgb(230, 85, 13);
  }
}

.entry-content a:not(.button):not(.cta-link):not(.file_browse) {
  color: #009EE0;
  border-bottom: 1px dotted #009EE0;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
.half-col {
  h2 {
    margin: 3px 0 40px 0;
  } 
}