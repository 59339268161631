.posizioni-aperte {
  @include clearfix;
  display: flex;
  flex-wrap: wrap;
  .posizione {
    margin-bottom: 40px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0,0,0,0.16);
    float: left;
    flex: 0 0 30%;
    margin-left: 5%;
    &:nth-child(3n+1) {
      margin-left: 0;
    }
    @include l-down {
      flex: 0 0 45%;
      margin-left: 0;
      &:nth-child(even) {
        margin-left: 10%;
      }
    }
    @include m-down {
      flex: 0 0 100%;
      &:nth-child(even) {
        margin-left: 0;
      }
    }
  }
}