

@mixin clearfix {
	&:after {
		content: ".";
		display: block;
		clear: both;
		visibility: hidden;
		line-height: 0;
		height: 0;
	}
}
@mixin block-center {
  margin: { 
    left: auto;
    right: auto;
  }
}
@mixin center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
@mixin vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
@mixin both-center {
  @include center;
  @include vertical-center;
  transform: translate(-50%, -50%);
}
@mixin antialias {
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
}
@mixin pseudo($display: block, $pos: absolute, $content: ''){
    content: $content;
    display: $display;
    position: $pos;
}

@mixin column($media,$num,$first:false,$right:false) {
	// require('gulp-group-css-media-queries');
	@if ($right == false) {
		float: left;
   } @else {
    float: right;
  }
  margin-left: grid($media,'gutter');
  @if ($first == true or $num == 12) {
    margin-left: 0;
  }
  width: (grid($media,'column') * $num) + (grid($media,'gutter') * ($num - 1));

}

@mixin column-percentage($media,$num:6,$first:false,$right:false){
	@if ($right == false) {
		float: left;
   } @else {
    float: right;
  }
  $col-width: percentage($num / 12);
  width: $col-width;	
  padding-left: grid($media,'gutter') / 2;
  padding-right: grid($media,'gutter') / 2;
  box-sizing: border-box;
  margin: 0;
}


@function grid( $mediaqueries, $param:false) {
	$val: 1;
	@if $param == "column" {
		$val: 2
   } @else if $param == "gutter" {
    $val: 3
    } @else {
      $val: 1
    }
    @return nth(map-get($grids, $mediaqueries),$val)
  }

  @mixin offsetbg($val:9999em){
   margin-left: -$val;
   padding-left: $val;
   margin-right: -$val;
   padding-right: $val;
 }


 %assistive-text {
   border: 0;
   clip: rect(0 0 0 0);
   height: 1px;
   margin: -1px;
   overflow: hidden;
   padding: 0;
   position: absolute;
   width: 1px;
 }
// generic-utilis
@mixin assistive-text 	{ @extend %assistive-text; }

@mixin ir() {
  display: block;
  border: 0;
  text-indent: -999em;
  overflow: hidden;
  background-color: transparent;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr;
  & br {
   display: none;
 }
 a,a:visited { display: block; width: 100%; height: 100%; }
}

@mixin imagereplacement-retina($url-1x,$url-2x,$width,$height) {
	@include ir();
	background-image: url($url-1x);
	width: $width;
	height: $height;
  @media #{$media-retina} {
    background-image: url($url-2x);
    background-size: $width $height;
    background-repeat: no-repeat;
  }
}

@mixin svgfallback($url) { 
  background-image: url(#{$url}.png);
  background-image: none, url(#{$url}.svg);	
}

@mixin retina-image($file-1x, $file-2x, $width-1x, $height-1x) {
  background-image: url($file-1x);
  background-repeat: no-repeat;
  @media #{$media-retina} {
    background-image: url($file-2x);
    background-size: $width-1x $height-1x;
  }
}

@mixin bordered-triangle($size, $border-width, $color, $border-color, $direction, $alt: false) { 
  $back-size: $size + ($border-width * 2);
  position: relative;
  
  min-width: $size;
  min-height: $size;
  
  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
  }
  
  &:after {
   @include triangle($size, $color, $direction);
 }

 &:before {
  @include triangle($back-size, $border-color, $direction);
  $mid-border-width: ($border-width / 2);
  $border-width-and-mid: $border-width + $mid-border-width; 

  @if ($alt == true) {
    $mid-border-width: 0;
    $border-width-and-mid: $border-width * 2;
  }

  @if ($direction == up) or ($direction == down) {
    margin-left: - $border-width;

    @if ($direction == down) {
      margin-top: -$mid-border-width;
      } @else {
        margin-top: - $border-width-and-mid;
      }  

      } @else if ($direction == right) or ($direction == left) {
        margin-top: - $border-width;

        @if ($direction == right) {
          margin-left: - $mid-border-width;
          } @else {
            margin-left: - $border-width-and-mid;
          }
        }
      }
    }

    @mixin font-face-all($nome,$file,$path,$woff2: false) {
     @font-face {
       font-family: '#{$nome}';
       @if $woff2 == true {
         src: url('#{$path}#{$file}.eot');
         src: url('#{$path}#{$file}.eot?#iefix') format('embedded-opentype'),
         url('#{$path}#{$file}.woff') format('woff'),
         url('#{$path}#{$file}.woff2') format('woff2'),    
         url('#{$path}#{$file}.ttf') format('truetype');
         } @else {
           src: url('#{$path}#{$file}.eot');
           src: url('#{$path}#{$file}.eot?#iefix') format('embedded-opentype'),
           url('#{$path}#{$file}.woff') format('woff'),
           url('#{$path}#{$file}.ttf') format('truetype');		
         }
         font-weight: normal;
         font-style: normal;
       }
     }

// simplify fontface
// https://css-tricks.com/snippets/css/using-font-face/
// Chrome	Safari	Firefox	Opera	IE	Android	iOS
// 3.5+		3+		3.5+	10.1+	9+	2.2+	4.3+

@mixin font-face($nome,$file,$path,$woff2: false) {
	@font-face {
   font-family: '#{$nome}';
   @if $woff2 == true {
     src: url('#{$path}#{$file}.woff2') format('woff2'),
     url('#{$path}#{$file}.woff') format('woff'),    
     url('#{$path}#{$file}.ttf') format('truetype');
     } @else {
       src: url('#{$path}#{$file}.woff') format('woff'),    
       url('#{$path}#{$file}.ttf') format('truetype');		
     }
     font-weight: normal;
     font-style: normal;
   }
 }


 @mixin triangle($direction, $color, $width, $height) {
   width: 0;
   height: 0;
   border-style: solid;
   @if $direction == "n" {
    border-width: 0 #{$width/2} $height #{$width/2};
    border-color: transparent transparent $color transparent;
    } @else if $direction == "e" {
      border-width: #{$height/2} 0 #{$height/2} $width;
      border-color: transparent transparent transparent $color;
      } @else if $direction == "s" {
        border-width: $height #{$width/2} 0 #{$width/2};
        border-color: $color transparent transparent transparent;
        } @else if $direction == "w" {
          border-width: #{$height/2} $width #{$height/2} 0;
          border-color: transparent $color transparent transparent;
        }
      }

      @mixin insert-icon($content,$font-icon:'icons') {
       content: $content;
       font:16px/100% $font-icon;
       @include antialias();
       font-style: normal !important;
       font-weight: normal !important;
       font-variant: normal !important;
       text-transform: none !important;
       text-indent:0;
     }


     @mixin nobr(){
       br { 
        content: " ";
        &:after {
         content: " ";
       }
     }
   }

   @mixin input-appearance($placeholder:true) {
     appearance: none;
     @if $placeholder == true {
      &::-webkit-input-placeholder {
        color: darken($lightgrey, 10%);
      }
      &::-moz-placeholder {
        color: darken($lightgrey, 10%);
      }
      &:-moz-placeholder {
        color: darken($lightgrey, 10%);
      }
      &:-ms-input-placeholder {
        color: darken($lightgrey, 10%);
      }
    }	
    &:focus {
      outline: none;
    }
  }

