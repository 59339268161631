.checker {
	width: 20px;
	height: 20px;
	position: relative;
	input {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		margin: 0;
		opacity: 0;
	}
	span {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		&.checked {
			&:before {
				opacity: 1;
			}
		}
		&:before {
			//@include icon("\E008",14px,15px);
			@include insert-icon('\E00A');
			width: 18px;
			text-align: center;
			position: absolute;
			top: 1px;
			left: 0;
			opacity: 0;
			transition: opacity .5s ease;
		}
	}
}

	form {
		@include clearfix;
		.form-group {
			@include clearfix;
			.input-group {
				width: 100%;
				position: relative;
				line-height: 1.8;
				margin-bottom: 20px;				
				&.half-width {
					float:left;
					width: 48%;
					&+.half-width {
						margin-left: 4%;
					}
					@include s-down {
						float: none;
						width: 100%;
						&+.half-width {
							margin-left: 0;
						}
					}
				}
				input {							
					width: 100%;				
					display: block;
					font-size: 22px;
					line-height: 40px;
					height: 40px;
					padding: 5px;
					border-style: none;
					border-width: 0;
					border-bottom: 2px solid #ccc;
					margin-bottom: 5px;
					background: #f9f9f9;
					font-family: $sans1;
					&:focus {
						outline: none;
						border-bottom-color: $main-blue;
					}
					&.invalid {
						border-bottom-color: $rosso;
						&:focus {
							border-bottom-color: $main-blue;
						}							
					}
					&.valid {
						border-bottom-color: $verde;
						&:focus {
							border-bottom-color: $main-blue;
						}
					}
					&::-webkit-input-placeholder { /* WebKit, Blink, Edge */
						color:    #ccc;
						font-size: 18px;
					}
					&:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
						color:    #ccc;
						font-size: 18px;
						opacity:  1;
					}
					&::-moz-placeholder { /* Mozilla Firefox 19+ */
						color:    #ccc;
						font-size: 18px;
						opacity:  1;
					}
					&:-ms-input-placeholder { /* Internet Explorer 10-11 */
						color:    #ccc;
						font-size: 18px;
					}
					&::-ms-input-placeholder { /* Microsoft Edge */
						color:    #ccc;
						font-size: 18px;
					}
					&[type="checkbox"] {
						display: inline;
						width: auto;
						height: auto;
					}
					&[type="radio"] {
						display: inline;
						width: auto;
						height: auto;
					}
				}	
				label {					
					font-size: 18px;
					font-weight: 400;
					padding-left: 0;
					color: rgba(0,0,0,0.8);
				}
				.wpcf7-list-item  {
					&.first {
						margin: 0;
					}
					label {
						white-space: nowrap;
						display: inline;
						font-size: 18px;
						font-weight: 400;
						line-height: 20px;
						position: relative;
						cursor: pointer;
						@include s-down {
							white-space: normal;
						}
					}
				}

				select {
					width: 100%;
					-webkit-appearance: none; 
					-moz-appearance: none;
					appearance: none; /* remove default arrow */
					background: #f9f9f9 url("../img/freccia-select.svg") center right no-repeat;
					&::-ms-expand { 
						display: none; /* hide the default arrow in ie10 and ie11 */
					}
					padding: 5px 25px 5px 5px;
					border: none;
					font-size: 22px;
					font-family: $sans1;
					border-style: none;
					border-width: 0;
					border-bottom: 2px solid #ccc;
					positon: relative;
					&:focus {
						outline: none;
						border-bottom-color: $main-blue;
					}
				}
				textarea {
					background: #f9f9f9;
					padding: 5px;
					outline: none;
					border-style: none;
					border-width: 0;
					border-bottom: 2px solid #ccc;
					font-family:  $sans1;
					font-size: 18px;
					width: 100%;
					&::-webkit-input-placeholder { /* WebKit, Blink, Edge */
						color:    #ccc;
						font-size: 18px;
					}
					&:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
						color:    #ccc;
						font-size: 18px;
						opacity:  1;
					}
					&::-moz-placeholder { /* Mozilla Firefox 19+ */
						color:    #ccc;
						font-size: 18px;
						opacity:  1;
					}
					&:-ms-input-placeholder { /* Internet Explorer 10-11 */
						color:    #ccc;
						font-size: 18px;
					}
					&::-ms-input-placeholder { /* Microsoft Edge */
						color:    #ccc;
						font-size: 18px;
					}
					&:focus {
						border-bottom-color: $main-blue;
					}
				}

			}
		}

		.drop_uploader.drop_zone .text {
			font-family: inherit;
			font-size: 20px;
			color: #0077ac;
		}

		.submit-container {
			text-align: center;
			margin: 30px 0;
		}

		input[type="checkbox"], input[type="checkbox"] {
			cursor: pointer;
		}

		input[type="submit"] {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			display: block;
			cursor: pointer;
			display: inline-block;
			font-size: 18px;
			@extend a.button;
			font-family: $sans1;
			@include s-down {
				font-size: 16px;
			}
			&:hover {
				background-color: $main-blue;
				color: white;
			}
			&:disabled {
				border-color: $light-grey;
				color: $light-grey;
				opacity: .3;
			}
		}
	}

/*Contact Form styles*/
div.wpcf7 {
	margin: 0;
	padding: 0;
}

div.wpcf7-response-output {
	margin: 2em 0.5em 1em;
	padding: 0.2em 1em;
}

div.wpcf7 .screen-reader-response {
	position: absolute;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	width: 1px;
	margin: 0;
	padding: 0;
	border: 0;
}

div.wpcf7-mail-sent-ok {
	background-color: rgba(#398f14, .3);
	font-weight: 500;
	padding: 8px;
	text-align: center;
	border-radius: 10px;
}

div.wpcf7-mail-sent-ng {
	border: 2px solid #ff0000;
}

div.wpcf7-spam-blocked {
	border: 2px solid #ffa500;
}

div.wpcf7-validation-errors {
	background: rgba(red, .2);
	font-weight: 500;
	padding: 8px;
	text-align: center;
	border-radius: 10px;
}

.wpcf7-form-control-wrap {
	position: relative;
}

span.wpcf7-not-valid-tip {
	color: #f00;
	font-size: 1em;
	font-weight: normal;
	display: block;
}

.use-floating-validation-tip span.wpcf7-not-valid-tip {
	position: absolute;
	top: 20%;
	left: 20%;
	z-index: 100;
	border: 1px solid #ff0000;
	background: #fff;
	padding: .2em .8em;
}

span.wpcf7-list-item {
	display: inline-block;
	margin: 0 0 0 1em;
}

span.wpcf7-list-item-label::before,
span.wpcf7-list-item-label::after {
	content: " ";
}

.wpcf7-display-none {
	display: none;
}

div.wpcf7 .ajax-loader {
	visibility: hidden;
	display: inline-block;
	background-image: url('../../images/ajax-loader.gif'); // esiste questo path?
	width: 16px;
	height: 16px;
	border: none;
	padding: 0;
	margin: 0 0 0 4px;
	vertical-align: middle;
}

div.wpcf7 .ajax-loader.is-active {
	visibility: visible;
}

div.wpcf7 div.ajax-error {
	display: none;
}

div.wpcf7 .placeheld {
	color: #888;
}

div.wpcf7 .wpcf7-recaptcha iframe {
	margin-bottom: 0;
}

div.wpcf7 input[type="file"] {
	cursor: pointer;
}

div.wpcf7 input[type="file"]:disabled {
	cursor: default;
}

div.wpcf7 .wpcf7-submit {
	background: #fff;
}
/*Conditional Fields styles*/

/* initially hide all groups (even before JS is loaded), so the page will never render them while loading */
[data-class="wpcf7cf_group"] {
    display:none;
}

/*Drop Uploader styles*/


.drop_uploader.drop_zone {
	width: 100%;
	min-height: 100px;
	text-align: center;
	border: 1px dashed $grigio;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	margin: 10px 0;
	a {
		text-align: center;
			display: inline-block;
			color: $main-blue;
			height: 40px;
			min-width: 220px;
			border: 1px solid #0061C4;
			border-radius: 100px;
			box-shadow: 0 0 20px 2px rgba($main-blue,0.2);
			padding: 0 20px;
			line-height: 40px;
			position: relative;
			overflow: hidden;
			z-index: 1;
			transition: all .4s ease;
			vertical-align: middle;
			&:after {
				@include pseudo;
				height: 200%;
				width: 100%;
				background-color: #0061C4;
				z-index: -1;
				bottom: 100%;
				left: 0;
				will-change: transform;
				transition: all .4s ease;
			}
			&:before {
				@include pseudo;
				height: 100%;
				width: 100%;
				top: 0;
				left: 0;
				background: white;
				z-index: -1;
			}
			&:hover {
				background: transparent;
				color: white;
				border-color: white;
				&:after {
					transform: translateY(55%);
				} 
			}
			@include xxl-up {
				height: 2.5vw;
				line-height: 2.5vw;
				min-width: 13.75vw;
				box-shadow: 0 0 1.25vw .3125vw rgba($main-blue,0.2);
				padding: 0 1.25vw;
			}
		}
}

.drop_uploader.drop_zone.hover {
	border: 3px dashed #4a90e2;
}

.drop_uploader.drop_zone .text_wrapper {
	margin-top: 30px;
}

.drop_uploader.drop_zone .text_wrapper i {
	font-size: 50px;
	color: $grigio;
	position: relative;
	top: 14px;
	margin-right: 25px;
}

.drop_uploader.drop_zone .text {
	font-size: 24px;
	color: $grigio;
	font-weight: 500;
}

.drop_uploader.drop_zone ul.files {
	width: 90%;
	margin: 0 auto;
	text-align: left;
	list-style: none;
	margin: 10px auto 35px;
}

.drop_uploader.drop_zone ul.files li {
	font-size: 18px;
	color: $grigio;
	background-color: #f4f4f4;
	border-top: 2px solid #f4f4f4;
	border-bottom: 2px solid #f4f4f4;
	margin: 0;
	padding: 5px;
}

.drop_uploader.drop_zone ul.files li i.action-delete {
	color: #ff0000;
	float: right;
	cursor: pointer;
}

.drop_uploader.drop_zone ul.files.thumb {
	width: 90%;
	text-align: left;
	list-style: none;
	margin-bottom: 35px;
	overflow: auto;
}

.drop_uploader.drop_zone ul.files.thumb li {
	min-width: 100px;
	display: block;
	float: left;
	font-size: 18px;
	color: $grigio;
	background-color: transparent;
	border: none;
	margin: 0;
	padding: 5px 15px;
	text-align: center;
}

.drop_uploader.drop_zone ul.files.thumb li div.thumbnail {
	width: 100px;
	height: 100px;
	border-radius: 8px 8px 8px 8px;
	-moz-border-radius: 8px 8px 8px 8px;
	-webkit-border-radius: 8px 8px 8px 8px;
	background-size: cover;
	margin: 0 auto;
	border: 1px solid $grigio;
}

.drop_uploader.drop_zone ul.files.thumb li div.thumbnail .du_hover_layer {
	width: 98px;
	height: 98px;
	border-radius: 7px;
	background: #fff;
	opacity: 0;
	position: absolute;
	-webkit-transition: opacity 0.1s ease-in;
	-moz-transition: opacity 0.1s ease-in;
	transition: opacity 0.1s ease-in;
}

.drop_uploader.drop_zone ul.files.thumb li div.thumbnail:hover .du_hover_layer {
	opacity: .8;
	position: absolute;
	-webkit-transition: opacity 0.1s ease-in;
	-moz-transition: opacity 0.1s ease-in;
	transition: opacity 0.1s ease-in;
}

.drop_uploader.drop_zone ul.files li .du_progress {
	display: inline-block;
	float: right;
	margin: 4px 9px;
}

.drop_uploader.drop_zone ul.files.thumb li .du_progress {
	width: 0px;
	height: 0px;
	margin-top: -100px;
	position: relative;
	right: 75px;
}

.drop_uploader.drop_zone ul.files.thumb li .du_progress canvas {
	margin-top: 25px;
}

.drop_uploader.drop_zone ul.files.thumb li div.thumbnail i {
	font-size: 32px;
	position: relative;
	top: calc(50% - 16px);
	display: block;
	color: #999999;
}

.drop_uploader.drop_zone ul.files.thumb li div.thumbnail.rotate_90 {
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	transform: rotate(-90deg);
}

.drop_uploader.drop_zone ul.files.thumb li div.thumbnail.rotate_180 {
	-webkit-transform: rotate(-180deg);
	-moz-transform: rotate(-180deg);
	-ms-transform: rotate(-180deg);
	-o-transform: rotate(-180deg);
	transform: rotate(-180deg);
}

.drop_uploader.drop_zone ul.files.thumb li div.thumbnail.rotate_270 {
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
}

.drop_uploader.drop_zone ul.files.thumb.ajax li div.thumbnail:hover i {
	visibility: hidden;
	opacity: 0;
	display: none;
}

.drop_uploader.drop_zone ul.files.thumb li div.thumbnail i.action-delete {
	color: $rosso;
	visibility: hidden;
	opacity: 0;
	float: none;
	-webkit-transition: opacity 0.1s ease-in;
	-moz-transition: opacity 0.1s ease-in;
	transition: opacity 0.1s ease-in;
}

.drop_uploader.drop_zone ul.files.thumb li div.thumbnail:hover i.action-delete {
	visibility: visible;
	opacity: 1;
	display: block;
	cursor: pointer;
	-webkit-transition: opacity 0.1s ease-in;
	-moz-transition: opacity 0.1s ease-in;
	transition: opacity 0.1s ease-in;
}

.drop_uploader.drop_zone ul.files.thumb li span.title {
	width: 100px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
}

.drop_uploader.drop_zone ul.files li:nth-child(even) {
	background-color: transparent;
}

.drop_uploader.drop_zone ul.files li i {
	font-size: 20px;
	position: relative;
	top: 2px;
	margin: 0px 10px;
}

.drop_uploader.drop_zone .errors p {
	color: $rosso;
}

.drop_uploader.drop_zone input[type=file] {
	display: none;
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
}

.row {
	@include clearfix;
}

// $body-bg: #fff;


// // Shadows
// $shadow-inset: inset 0 2px 2px 0 rgba(0,0,0,.14);
// $shadow-0: 0 0 1px rgba(0, 0, 0, 0);
// $shadow-2: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
// $shadow-3: 0 3px 4px 0 rgba(0,0,0,.14),0 3px 3px -2px rgba(0,0,0,.2),0 1px 8px 0 rgba(0,0,0,.12);
// $shadow-4: 0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12),0 2px 4px -1px rgba(0,0,0,.2);
// $shadow-6: 0 6px 10px 0 rgba(0,0,0,.14),0 1px 18px 0 rgba(0,0,0,.12),0 3px 5px -1px rgba(0,0,0,.2);
// $shadow-8: 0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 2px rgba(0,0,0,.12),0 5px 5px -3px rgba(0,0,0,.2);

// // Font Sizes
// $mf-font-size: 1rem !default;
// $mf-active-font-size: $mf-font-size * 0.8 !default;
// $mf-active-top: -$mf-font-size !default;

// // Margin & Paddings
// $mf-gap: $mf-font-size * 1.5 !default;
// $mf-spacer: $mf-font-size / 8 !default;
// $mf-fieldset-margin: 0 0 $mf-gap * 2 !default;
// $mf-checkbox-gap: $mf-font-size * 2 !default;
// $mf-checkbox-top: 0 !default;
// $mf-radio-margin: $mf-font-size / -4 !default;

// // Colors
// $mf-input-color: #333 !default;
// $mf-border-color: #999 !default;
// $mf-label-color: lighten($mf-border-color, 10%) !default;
// $mf-active-color: darken($mf-border-color, 10%) !default;
// $mf-focus-color: #337ab7 !default;
// $mf-error-color: #d9534f !default;

// // Animation
// $mf-transition-speed: 0.28s !default;

// .container {
// 	position: relative;
// 	max-width: 40rem;
// 	margin: 5rem auto;
// 	background: $body-bg;
// 	width: 100%;
// 	padding: 3rem 5rem 0;
// 	border-radius: 1px;
	
// 	&::before {
// 		content: '';
// 		position: absolute;
// 		top: 0;
// 		left: 0;
// 		right: 0;
// 		bottom: 0;
// 		box-shadow: $shadow-8;
// 		transform: scale(0.98);
// 		transition: transform $mf-transition-speed ease-in-out;
// 		z-index: -1;
// 	}
	
// 	&:hover::before {
// 		transform: scale(1);
// 	}
// }

// .button-container {
// 	text-align: center;
// }

// // The Styles for the form
// fieldset {
// 	margin: $mf-fieldset-margin;
// 	padding: 0;
// 	border: none;
// }

// .form-radio,
// .form-group {
// 	position: relative;
// 	margin-top: ($mf-gap * 1.5);
// 	margin-bottom: ($mf-gap * 1.5);
// }

// .form-inline {
// 	> .form-group,
// 	> .btn {
// 		display: inline-block;
// 		margin-bottom: 0;
// 	}
// }

// .form-help {
// 	margin-top: $mf-spacer;
// 	margin-left: $mf-spacer;
// 	color: $mf-label-color;
// 	font-size: $mf-active-font-size;

// 	.checkbox &,
// 	.form-radio &,
// 	.form-group & {
// 		position: absolute;
// 		width: 100%;
// 	}

// 	.checkbox & {
// 		position: relative;
// 		margin-bottom: $mf-font-size;
// 	}

// 	.form-radio & {
// 		padding-top: ($mf-spacer * 2);
// 		margin-top: -$mf-font-size;
// 	}
// }

// .form-group {
// 	// scss-lint:disable QualifyingElement, NestingDepth
// 	input {
// 		height: ($mf-font-size * 1.9);
// 	}

// 	textarea {
// 		resize: none;
// 	}

// 	select {
// 		width: 100%;
// 		font-size: $mf-font-size;
// 		height: ($mf-font-size * 1.6);
// 		padding: $mf-spacer $mf-spacer ($mf-spacer / 2);
// 		background: none;
// 		border: none;
// 		line-height: 1.6;
// 		box-shadow: none;
// 	}

// 	.control-label {
// 		position: absolute;
// 		top: ($mf-font-size / 4);
// 		pointer-events: none;
// 		padding-left: $mf-spacer;
// 		z-index: 1;
// 		color: $mf-label-color;
// 		font-size: $mf-font-size;
// 		font-weight: normal;
// 		transition: all $mf-transition-speed ease;
// 	}

// 	.bar {
// 		position: relative;
// 		border-bottom: ($mf-font-size / 16) solid $mf-border-color;
// 		display: block;
		
// 		&::before {
// 			content: '';
// 			height: ($mf-font-size / 8);
// 			width: 0;
// 			left: 50%;
// 			bottom: ($mf-font-size / -16);
// 			position: absolute;
// 			background: $mf-focus-color;
// 			transition: left $mf-transition-speed ease, width $mf-transition-speed ease;
// 			z-index: 2;
// 		}
// 	}

// 	input,
// 	textarea {
// 		display: block;
// 		background: none;
// 		padding: $mf-spacer $mf-spacer ($mf-spacer / 2);
// 		font-size: $mf-font-size;
// 		border-width: 0;
// 		border-color: transparent;
// 		line-height: 1.9;
// 		width: 100%;
// 		color: transparent;
// 		transition: all $mf-transition-speed ease;
// 		box-shadow: none;
// 	}

// 	input[type="file"] {
// 		line-height: 1;

// 		~ .bar {
// 			display: none;
// 		}
// 	}

// 	select,
// 	input:focus,
// 	input:valid,
// 	input.form-file,
// 	input.has-value,
// 	textarea:focus,
// 	textarea:valid,
// 	textarea.form-file,
// 	textarea.has-value {
// 		color: $mf-input-color;

// 		~ .control-label {
// 			font-size: $mf-active-font-size;
// 			color: $mf-active-color;
// 			top: $mf-active-top;
// 			left: 0;
// 		}
// 	}

// 	select,
// 	input,
// 	textarea {
// 		&:focus {
// 			outline: none;

// 			~ .control-label {
// 				color: $mf-focus-color;
// 			}

// 			~ .bar {
// 				&::before {
// 					width: 100%;
// 					left: 0;
// 				}
// 			}
// 		}
// 	}
// }

// // Radio & Checkbox
// .checkbox,
// .form-radio {
// 	label {
// 		position: relative;
// 		cursor: pointer;
// 		padding-left: $mf-checkbox-gap;
// 		text-align: left;
// 		color: $mf-input-color;
// 		display: block;
// 	}

// 	input {
// 		width: auto;
// 		opacity: 0.00000001;
// 		position: absolute;
// 		left: 0;
// 	}
// }

// .radio {
// 	margin-bottom: $mf-font-size;

// 	.helper {
// 		position: absolute;
// 		top: $mf-radio-margin;
// 		left: $mf-radio-margin;
// 		cursor: pointer;
// 		display: block;
// 		font-size: $mf-font-size;
// 		user-select: none;
// 		color: $mf-border-color;

// 		&::before,
// 		&::after {
// 			content: '';
// 			position: absolute;
// 			left: 0;
// 			top: 0;
// 			margin: ($mf-spacer * 2);
// 			width: $mf-font-size;
// 			height: $mf-font-size;
// 			transition: transform $mf-transition-speed ease;
// 			border-radius: 50%;
// 			border: ($mf-font-size / 8) solid currentColor;
// 		}

// 		&::after {
// 			transform: scale(0);
// 			background-color: $mf-focus-color;
// 			border-color: $mf-focus-color;
// 		}
// 	}

// 	label:hover .helper {
// 		color: $mf-focus-color;
// 	}

// 	// scss-lint:disable QualifyingElement, NestingDepth
// 	input:checked {
// 		~ .helper {
// 			&::after {
// 					transform: scale(0.5);
// 			}
// 			&::before {
// 				color: $mf-focus-color;
// 			}
// 		}
// 	}
// }

// .checkbox {
// 	// scss-lint:disable QualifyingElement
	
// 	margin-top: ($mf-gap * 2);
// 	margin-bottom: $mf-font-size;
	
// 	.helper {
// 		color: $mf-border-color;
// 		position: absolute;
// 		top: $mf-checkbox-top;
// 		left: 0;
// 		width: $mf-font-size;
// 		height: $mf-font-size;
// 		z-index: 0;
// 		border: ($mf-font-size / 8) solid currentColor;
// 		border-radius: ($mf-font-size / 16);
// 		transition: border-color $mf-transition-speed ease;
		
// 		&::before,
// 		&::after {
// 			position: absolute;
// 			height: 0;
// 			width: ($mf-font-size * 0.2);
// 			background-color: $mf-focus-color;
// 			display: block;
// 			transform-origin: left top;
// 			border-radius: ($mf-font-size / 4);
// 			content: '';
// 			transition: opacity $mf-transition-speed ease, height 0s linear $mf-transition-speed;
// 			opacity: 0;
// 		}
		
// 		&::before {
// 			top: ($mf-font-size * 0.65);
// 			left: ($mf-font-size * 0.38);
// 			transform: rotate(-135deg);
// 			box-shadow: 0 0 0 ($mf-font-size / 16) $body-bg;
// 		}

// 		&::after {
// 			top: ($mf-font-size * 0.3);
// 			left: 0;
// 			transform: rotate(-45deg);
// 		}
// 	}
	
// 	label:hover .helper {
// 		color: $mf-focus-color;
// 	}
	
// 	input:checked {
// 		~ .helper {
// 			color: $mf-focus-color;
			
// 			&::after,
// 			&::before {
// 				opacity: 1;
// 				transition: height $mf-transition-speed ease;
// 			}

// 			&::after {
// 				height: ($mf-font-size / 2);
// 			}

// 			&::before {
// 				height: ($mf-font-size * 1.2);
// 				transition-delay: $mf-transition-speed;
// 			}
// 		}
// 	}
// }

// .radio + .radio,
// .checkbox + .checkbox {
// 	margin-top: $mf-font-size;
// }

// .has-error {
// 	.legend.legend,
// 	&.form-group .control-label.control-label {
// 		// Prevent !importantRule
// 		color: $mf-error-color;
// 	}
	
// 	&.form-group,
// 	&.checkbox,
// 	&.radio,
// 	&.form-radio {
// 		.form-help,
// 		.helper {
// 			color: $mf-error-color;
// 		}
// 	}
	
// 	.bar {
// 		&::before {
// 			background: $mf-error-color;
// 			left: 0;
// 			width: 100%;
// 		}
// 	}
// }

// .button {
// 	position: relative;
// 	background: currentColor;
// 	border: 1px solid currentColor;
// 	font-size: ($mf-font-size * 1.1);
// 	color: lighten($mf-focus-color, 10%);
// 	margin:( $mf-gap * 2) 0;
// 	padding: ($mf-gap / 2) ($mf-gap * 2);
// 	cursor: pointer;
// 	transition: background-color $mf-transition-speed ease, color $mf-transition-speed ease, box-shadow $mf-transition-speed ease;
// 	overflow: hidden;
// 	box-shadow: $shadow-2;
	
// 	span {
// 		color: #fff;
// 		position: relative;
// 		z-index: 1;
// 	}
	
// 	&::before {
// 		content: '';
// 		position: absolute;
// 		background: darken($mf-focus-color, 40%);
// 		border: 50vh solid darken($mf-focus-color, 20%);
// 		width: 30vh;
// 		height: 30vh;
// 		border-radius: 50%;
// 		display: block;
// 		top: 50%;
// 		left: 50%;
// 		z-index: 0;
// 		opacity: 1;
// 		transform: translate(-50%, -50%) scale(0);
// 	}
	
// 	&:hover {
// 		color: $mf-focus-color;
// 		box-shadow: $shadow-6;
// 	}
	
// 	&:active,
// 	&:focus {
// 		&::before {
// 			transition: transform ($mf-transition-speed * 4) ease, opacity $mf-transition-speed ease ($mf-transition-speed * 1.3);
// 			transform: translate(-50%, -50%) scale(1);
// 			opacity: 0;
// 		}
// 	}
	
// 	&:focus {
// 		outline: none;
// 	}
// }