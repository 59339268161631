/* GRID */

body, html {
  height: 100%;
}
#trigger {
  @include s-down {
    padding-top: 107px;
  }
}

.wrapper {
  position: relative;
  padding-right: 100px;
  padding-left: 100px;
  max-width: 1240px;
  margin: 0 auto;
  pointer-events: none;
  @include clearfix;
  * {
    pointer-events: all;
  }
  @include s-down {
    padding-right: 20px;
    padding-left: 20px;
  }
  @include xs-down {
    padding-right: 15px;
    padding-left: 15px;
  }
  @include xxl-up {
    max-width: 77.5vw;
    padding-right: 6.25vw;
    padding-left: 6.25vw;
  }
}

//Per elementi che sbordano
@mixin outwrapper($direction) {
  width: calc(50% + 100px);
  @if ($direction == "left") {
    float: left;
    margin-left: -80px;
  } @elseif ($direction == "right") {
    float: right;
    margin-right: -80px;
  }
  @include s-down {
    float: none;
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: 0;
  } 
  @include xxl-up {
    width: calc(50% + 6.25vw);
    @if ($direction == "left") {
      margin-left: -5vw;
    } @elseif ($direction == "right") {
      margin-right: -5vw;
    }
  }
}

@mixin inwrapper($direction) {
  width: calc(50% - 20px);
  @if ($direction == "left") {
    float: left;
  } @elseif ($direction == "right") {
    float: right;
  }
  @include s-down {
    float: none;
    width: 100%;
  }
  @include xxl-up {
    width: calc(50% - 1.25vw);
  }
}

.col-wrapper {
  @include clearfix;
  padding-bottom: 50px;
  padding-top: 50px;
  margin-bottom: 50px;
  .text-col {
    @include inwrapper(left);
    &+.text-col {
      margin-left: 40px;
    }
  }
  .half-col {
    float: left;
    width: 50%;
    
    margin-left: 20px;
    @include s-down {
      float: none;
      height: auto;
      width: 100%;
      margin: 0;
    }
  }  
  @include xxl-up {
    padding-bottom: 3.125vw;
    padding-top: 3.125vw;
    margin-bottom: 3.125vw;
    .text-col+.text-col {
      margin-left: 2.5vw;
    }
    .half-col {
      height: 12.5vw;
      margin-left: 1.25vw;
    }
  }
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 50px;
  padding-top: 25px;
  &>div {
    flex: 0 0 45%;
    &:nth-child(odd) {
      margin-right: 10%;
    }
    @include s-down {
      flex: 0 0 100%;
      &:nth-child(odd) {
        margin-right: 0;
      }
    }
  }
  @include xxl-up {
    padding-bottom: 3.125vw;
    padding-top: 1.5625vw;
  }
}

.main-text-container {
  max-width: 80ch;

  &--article {
    margin: 40px 0;
  }
}

.thumbnail-bg {
  height: 300px;
  background-position: center;
  background-size: cover;
  margin: 20px 0 40px;
  @include xxl-up {
    height: 18.75vw;
    margin: 1.25vw 0 2.5vw;
  }
}

.vertical-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  pointer-events: all;
}