@font-face {
	font-family: 'HK Grotesk';
	src: url('../font/hkgrotesk-medium.woff2') format('woff2'), url('../font/hkgrotesk-medium.woff') format('woff'), url('../font/hkgrotesk-medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'HK Grotesk';
	src: url('../font/hkgrotesk-regular.woff2') format('woff2'), url('../font/hkgrotesk-regular.woff') format('woff'), url('../font/hkgrotesk-regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

.typed-fade-out {
	animation: .3s ease forwards fadeOut;
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes bounce {
	0%,
	20%,
	50%,
	80%,
	100% {
		transform: translateY(0);
	}
	40% {
		transform: translateY(-16px);
	}
	60% {
		transform: translateY(-8px);
	}
}

@keyframes bounceRight {
	0%,
	20%,
	50%,
	80%,
	100% {
		transform: translateX(0) rotate(-90deg);
	}
	40% {
		transform: translateX(-16px) rotate(-90deg);
	}
	60% {
		transform: translateX(-8px) rotate(-90deg);
	}
}
.bounce {
	animation: bounce 2s infinite;
}
.bounce-right {
	animation: bounceRight 2s infinite;
}


body {
	font-family: $sans1, Helvetica, Arial, sans-serif;
	color: $grigio;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	@include xxl-up {
		font-size: 1.125vw;
		line-height: 1.5vw;
	}
}

a,
a:visited {
	text-decoration: none;
	color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: $main-blue;
	margin: 0.8em 0 0.4em;
}

h1 {
	font-size: 36px;
	line-height: 38px;
	font-weight: 500;
	@include xxl-up {
		font-size: 2.25vw;
		line-height: 2.8125vw;
	}
}

h2 {
	font-size: 34px;
	font-weight: 500;
	line-height: 36px;
	@include xs-down {
		font-size: 28px;
		line-height: 30px;
	}
	@include xxl-up {
		font-size: 2.125vw;
		line-height: 2.5vw;
	}
}

h3 {
	font-size: 28px;
	font-weight: 500;
	line-height: 32px;
	@include xs-down {
		font-size: 24px;
		line-height: 26px;
	}
	@include xxl-up {
		font-size: 1.75vw;
		line-height: 2.25vw;
	}
}

h4 {
	font-size: 20px;
	font-weight: 500;
	line-height: 28px;
	@include xxl-up {
		font-size: 1.25vw;
		line-height: 1.75vw;
	}
}

.separator {
	padding: 20px 0;
	margin-top: 30px;
	border-top: 1px solid rgba(#009EE0, .4);
}

a.button {
	text-align: center;
	display: inline-block;
	color: $main-blue;
	height: 40px;
	min-width: 220px;
	border: 1px solid #0061C4;
	border-radius: 100px;
	box-shadow: 0 0 20px 2px rgba($main-blue, 0.2);
	padding: 0 20px;
	padding: 0 20px;
	line-height: 40px;
	position: relative;
	overflow: hidden;
	z-index: 1;
	transition: all .4s ease;
	&:after {
		@include pseudo;
		height: 200%;
		width: 100%;
		background-color: #0061C4;
		z-index: -1;
		bottom: 100%;
		left: 0;
		will-change: transform;
		transition: all .4s ease;
	}
	&:before {
		@include pseudo;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		background: white;
		z-index: -1;
	}
	&:hover {
		background: transparent;
		color: white;
		border-color: white;
		&:after {
			transform: translateY(55%);
		}
	}
	@include xxl-up {
		height: 2.5vw;
		line-height: 2.5vw;
		min-width: 13.75vw;
		box-shadow: 0 0 1.25vw .3125vw rgba($main-blue, 0.2);
		padding: 0 1.25vw;
	}
}

p {
	margin: 10px 0 15px;
	@include xxl-up {
		margin: .625vw 0 .9375vw
	}
}

.button-container {
	margin-top: 60px;
	@include m-down {
		margin-top: 40px;
		text-align: center;
	}
	@include s-down {
		margin-top: 20px;
	}
	@include xxl-up {
		margin-top: 3.75vw;
	}
	&-centered {
		text-align: center;
	}
}

a.cta-link {
	font-weight: 500;
	line-height: 40px;
	color: #009EE0;
	@include xxl-up {
		line-height: 2.5vw;
	}
	&:hover {
		border-bottom: 2px solid rgba(#009EE0, .4)
	}
}

.quiet {
	opacity: .7;
}

.show-on-medium {
	display: none;
	@include m-down {
		display: inline;
	}
}

.entry-content {
	margin-bottom: 40px;
	&-servizio {
		width: 100%;
		font-size: 20px;
		line-height: 28px;
		color: $main-blue;
		font-weight: 500;
	}
	img {
		max-width: 100%;
		height: 100%;
	}
	ul {
		list-style-type: disc;
		padding-left: 1em;
		margin: 1em 0 2em;
		li {
			line-height: 1.5;
		}
	}
	ol {
		list-style-type: decimal;
		padding-left: 1em;
		margin: 1em 0 2em;
		li {
			line-height: 1.5;
		}
	}
	table {
		margin: 30px 0;
		thead,
		tfoot {
			color: white;
			background-color: $main-blue;
			border: 1px solid white;
			text-align: left;
			th {
				padding: 5px;
			}
		}
		tr {
			background-color: rgba(#009EE0, .1);
			border: 1px solid white;
			&:nth-child(even) {
				background-color: #fff;
			}
		}
		td {
			padding: 5px;
		}
	}
	@include xxl-up {
		margin-bottom: 2.5vw;
		table {
			margin: 1.875vw;
			thead,
			tfoot {
				th {
					padding: .3125vw;
				}
			}
			td {
				padding: .3125vw;
			}
		}
	}
}

span.arrow {
	height: 1.5em;
	width: 1em;
	padding: 0 10px;
	background: url("../img/arrow.svg") center center no-repeat;
	background-size: contain;
	display: inline-block;
	vertical-align: middle;
	&.rotate {
		transform: rotate(-90deg);
		margin: 0 10px;
	}
}

.titolo {
	font-size: 36px;
	font-weight: 500;
	line-height: 40px;
	@include m-down {
		font-size: 30px;
		line-height: 34px;
	}
	@include s-down {
		font-size: 26px;
		line-height: 29px;
	}
	@include xs-down {
		font-size: 22px;
		line-height: 25px;
	}
}

.breadcrumbs {
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 500;
	color: $light-grey;
	span.arrow {
		height: 1em;
		margin: 0 2px 4px;
	}
	a {
		color: #009EE0;
	}
	&+h1 {
		margin-top: 0;
	}
}