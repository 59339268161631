// approfondire http://css-tricks.com/approaches-media-queries-sass/
// e valutare se implementare https://github.com/eduardoboucas/include-media  
@function lower-bound($range) {
    @if length($range) <=0 {
        @return 0;
    }
    @return nth($range, 1);
}

@function upper-bound($range) {
    @if length($range) < 2 {
        @return 999999999999;
    }
    @return nth($range, 2);
}

$xsmall-range: (0, $breakpoint-xs );
$small-range: ($breakpoint-xs + 1px, $breakpoint-s);
$medium-range: ($breakpoint-s + 1px, $breakpoint-m);
$large-range : ($breakpoint-m + 1px, $breakpoint-l);
$xlarge-range : ($breakpoint-l + 1px, $breakpoint-xl);
$xxlarge-range : ($breakpoint-xl);
$screen: "only screen";
$print: "print";
$media-retina : "#{$print}, #{$screen} and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 144dpi), screen and (min-resolution: 144dppx)";
$xsmall-up : $screen !default;
$xsmall-only : "#{$screen} and (max-width: #{upper-bound($xsmall-range)})" !default;
$xsmall-down : "#{$screen} and (max-width: #{upper-bound($xsmall-range)})" !default;
$xs : "#{$screen} and (max-width: #{upper-bound($xsmall-range)})" !default;
$small-up: "#{$screen} and (min-width:#{lower-bound($small-range)})" !default;
$small-only : "#{$screen} and (min-width:#{lower-bound($small-range)}) and (max-width:#{upper-bound($small-range)})" !default;
$small-down : "#{$screen} and (max-width:#{upper-bound($small-range)})" !default;
$medium-up : "#{$screen} and (min-width:#{lower-bound($medium-range)})" !default;
$medium-only : "#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})" !default;
$medium-down : "#{$screen} and (max-width:#{upper-bound($medium-range)})" !default;
$large-up: "#{$screen} and (min-width:#{lower-bound($large-range)})" !default;
$large-only : "#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})" !default;
$large-down : "#{$screen} and (max-width:#{upper-bound($large-range)})" !default;
$xlarge-up : "#{$screen} and (min-width:#{lower-bound($xlarge-range)})" !default;
$xlarge-only : "#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})" !default;
$xlarge-down : "#{$screen} and (max-width:#{upper-bound($xlarge-range)})" !default;
$xxlarge-up : "#{$screen} and (min-width:#{lower-bound($xxlarge-range)})" !default;
$xxlarge-only : "#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})" !default;
$xxlarge-down : "#{$screen} and (max-width:#{upper-bound($xxlarge-range)})" !default;

@mixin xs-up {@media #{$xsmall-up} {@content;}}
@mixin s-up {@media #{$small-up} {@content;}}
@mixin m-up {@media #{$medium-up} {@content; }}
@mixin l-up {@media #{$large-up} {@content; }}
@mixin xl-up {@media #{$xlarge-up} {@content; }}
@mixin xxl-up {@media #{$xxlarge-up} {@content; }}
@mixin xs-down {@media #{$xsmall-down} {@content; }}
@mixin s-down {@media #{$small-down} {@content; }}
@mixin m-down {@media #{$medium-down} {@content; }}
@mixin l-down {@media #{$large-down} {@content; }}
@mixin xl-down {@media #{$xlarge-down} {@content; }}
@mixin xs-only {@media #{$xsmall-only} {@content; }}
@mixin s-only {@media #{$small-only} {@content; }}
@mixin m-only {@media #{$medium-only} {@content; }}
@mixin l-only {@media #{$large-only} {@content; }}
@mixin xl-only {@media #{$xlarge-only} {@content; }}

@mixin print {@media #{$print} {@content;}}
@mixin retina {@media #{$media-retina} {@content;}}


$iphone4-up: 	"#{$screen} and (min-width:$iphone4)" !default;
$iphone4-only: 	"#{$screen} and (min-width:$iphone4) and (max-width:$iphone4)" !default;
$iphone4-down: 	"#{$screen} and (max-width:$iphone4)" !default;
$galaxy-up: 	"#{$screen} and (min-width:$galaxy)" !default;
$galaxy-only: 	"#{$screen} and (min-width:$galaxy) and (max-width:$galaxy)" !default;
$galaxy-down: 	"#{$screen} and (max-width:$galaxy)" !default;
$iphone6-up: 	"#{$screen} and (min-width:$iphone6)" !default;
$iphone6-only: 	"#{$screen} and (min-width:$iphone6) and (max-width:$iphone6)" !default;
$iphone6-down: 	"#{$screen} and (max-width:$iphone6)" !default;
$lumia-up: 		"#{$screen} and (min-width:$lumia)" !default;
$lumia-only: 	"#{$screen} and (min-width:$lumia) and (max-width:$lumia)" !default;
$lumia-down: 	"#{$screen} and (max-width:$lumia)" !default;
@mixin iphone4-up 	{	@media #{$iphone4-up} 	{	@content; } }
@mixin iphone4-only {	@media #{$iphone4-only} {	@content; } }
@mixin iphone4-down {	@media #{$iphone4-down} {	@content; } }
@mixin galaxy-up 	{	@media #{$galaxy-up} 	{	@content; } }
@mixin galaxy-only  {	@media #{$galaxy-only}  {	@content; } }
@mixin galaxy-down  {	@media #{$galaxy-down}  {	@content; } }
@mixin iphone6-up 	{	@media #{$iphone6-up} 	{	@content; } }
@mixin iphone6-only {	@media #{$iphone6-only} {	@content; } }
@mixin iphone6-down {	@media #{$iphone6-down} {	@content; } }
@mixin lumia-up 	{	@media #{$lumia-up} 	{	@content; } }
@mixin lumia-only   {	@media #{$lumia-only}   {	@content; } }
@mixin lumia-down   {	@media #{$lumia-down}   {	@content; } }

//Usage
//@include m-only { ... }
// // //
// Media Queries Specifiche, utilizzare con moooolta parsimonia!
// // //
$smartphones: "#{$screen} and (min-device-width : 320px) and (max-device-width : 480px)" !default; // Smartphones (portrait and landscape)
$smartphones-landscape: "#{$screen} and (min-width : 321px)" !default; // Smartphones (landscape)
$smartphones-portrait: "#{$screen} and (max-width : 320px)" !default; // Smartphones (portrait)
$ipad: "#{$screen} and (min-device-width : 768px) and (max-device-width : 1024px)" !default; // iPads (portrait and landscape)
$ipad-landscape: "#{$screen} and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape)" !default; // iPads (landscape)
$ipad-portrait: "#{$screen} and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait)" !default; // iPads (portrait)
$ipad3: "#{$screen} and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio : 2)" !default; // iPads 3 (portrait and landscape)
$ipad3-landscape: "#{$screen} and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2)" !default; // iPads 3 (landscape)
$ipad3-portrait: "#{$screen} and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2)" !default; // iPads 3 (portrait)
$iphone4: "#{$screen} and (min-device-width : 320px) and (max-device-width : 480px) and (-webkit-min-device-pixel-ratio : 2)" !default;
$iphone4-landscape: "#{$screen} and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2)" !default;
$iphone4-portrait: "#{$screen} and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2)" !default;
$iphone5: "#{$screen} and (min-device-width : 320px) and (max-device-width : 568px) and (-webkit-min-device-pixel-ratio : 2)" !default;
$iphone5-landscape: "#{$screen} and (min-device-width : 320px) and (max-device-width : 568px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2)" !default;
$iphone5-portrait: "#{$screen} and (min-device-width : 320px) and (max-device-width : 568px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2)" !default;
$iphone6: "#{$screen} and (min-device-width : 375px) and (max-device-width : 667px) and (-webkit-min-device-pixel-ratio : 2)" !default;
$iphone6-landscape: "#{$screen} and (min-device-width : 375px) and (max-device-width : 667px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2)" !default;
$iphone6-portrait: "#{$screen} and (min-device-width : 375px) and (max-device-width : 667px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2)" !default;
$iphone6plus: "#{$screen} and (min-device-width : 414px) and (max-device-width : 736px) and (-webkit-min-device-pixel-ratio : 2)" !default;
$iphone6plus-landscape: "#{$screen} and (min-device-width : 414px) and (max-device-width : 736px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2)" !default;
$iphone6plus-portrait: "#{$screen} and (min-device-width : 414px) and (max-device-width : 736px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2)" !default;
$galaxys3: "#{$screen} and (min-device-width : 320px) and (max-device-width : 640px) and (-webkit-min-device-pixel-ratio : 2)" !default;
$galaxys3-landscape: "#{$screen} and (min-device-width : 320px) and (max-device-width : 640px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2)" !default;
$galaxys3-portrait: "#{$screen} and (min-device-width : 320px) and (max-device-width : 640px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2)" !default;
$galaxys4: "#{$screen} and (min-device-width : 320px) and (max-device-width : 640px) and (-webkit-min-device-pixel-ratio : 3)" !default;
$galaxys4-landscape: "#{$screen} and (min-device-width : 320px) and (max-device-width : 640px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 3)" !default;
$galaxys4-portrait: "#{$screen} and (min-device-width : 320px) and (max-device-width : 640px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 3)" !default;
$galaxys5: "#{$screen} and (min-device-width : 360px) and (max-device-width : 640px) and (-webkit-min-device-pixel-ratio : 3)" !default;
$galaxys5-landscape: "#{$screen} and (min-device-width : 360px) and (max-device-width : 640px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 3)" !default;
$galaxys5-portrait: "#{$screen} and (min-device-width : 360px) and (max-device-width : 640px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 3)" !default;
