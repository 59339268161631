.landing {
	position: relative;
	@include clearfix;
	.text-container {
		@include inwrapper(left);
		height: 100%;
		@include clearfix;
		header {
			height: 47px;
			margin: 20px 0;
			padding-right: 40px;
			position: relative;
			z-index: 1;
			h1 {
				margin: 0;
				font-weight: 300;
				color: black;
				visibility: hidden;
			}
			.menu-toggle {
				position: absolute;
				right: 40px;
				top: 0;
				color: $main-blue;
				font-size: 16px;
				font-weight: 400;
			}
			@include s-down {
				display: none;
			}
			@include xxl-up {
				height: 2.8125vw;
				line-height: 2.8125vw;
				padding-right: 2.5vw;
				margin: 1.25vw 0;
				a {
					font-size: 1vw;
				}
			}
		}
		.servizi {
			position: absolute;
			top: 87px;
			bottom: 0;
			width: 50%;
			@include s-down {
				width: 100%;
				position: relative;
				top: auto;
				bottom: auto;
				min-height: 260px;
			}
			.more-link {
				position: absolute;
				bottom: 50px;
				@include m-down {
					bottom: 0;
				}
				@include s-down {
					left: 47px;
					bottom: 20px;
				}
				.arrow {
					animation: bounce 2s infinite;
				}
			}
			&-viewbox {
				padding-right: 100px;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				width: 100%;
				@include m-down {
					padding-right: 50px;
				}
				@include s-down {
					padding-right: 0;
					padding: 0 47px;
					position: relative;
					top: auto;
					transform: none;
					width: 100%;
				}
				@include xs-down {
					padding-right: 0;
				}
				&__singolo {
					display: none;
				}
				h2 {
					font-size: 34px;
					font-weight: 500;
					line-height: 36px;
					//min-height: 40px;
					display: inline-block;
					opacity: 0;
					white-space: pre-line;
					@include s-down {
						font-size: 28px;
						line-height: 34px;
						margin-bottom: 0;
					}
					span {}
					
				}
				p {
					color: $light-grey;
					font-size: 16px;
					font-weight: 400;
					line-height: 22px;
				}
				#typedHeaders,
				#typedPars {
					display: none;
				}
			}
			@include xxl-up {
				top: 5.4375 vw;
				a {
					bottom: 3.125vw;
				}
				&-viewbox {
					padding-right: 6.25vw;
					h2 {
						font-size: 2.125vw;
						line-height: 2.5vw;
						min-height: 2.5vw;
					}
					p {
						font-size: 1vw;
						line-height: 1.375vw;
					}
				}
			}
		}
	}
	.video-container {
		@include outwrapper(right);
		background-color: black;
		padding-bottom: calc(50% + 100px);
		box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3);
		overflow: hidden;
		position: relative;
		@include s-down {
			float: none;
			width: calc(100% + 30px);
			margin-left: -15px;
			margin-right: 0;
			padding-bottom: 100%;
		}
		.video-overlay {
			position: absolute;
			height: 100%;
			width: 100%;
			background: url("../img/placeholder-square.jpg") center center no-repeat;
			background-size: cover;
			display: none;
			z-index: 1;
			@include s-down {
				display: block;
				#play {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					height: 45px;
					width: 45px;
					background-color: white;
					border-radius: 50%;
					&:after {
						@include pseudo;
						left: 16px;
						top: 12px;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 10px 0 10px 17.32px;
						border-color: transparent transparent transparent rgb(0, 158, 224);
					}
				}
			}
			.play {
				position: absolute;
				cursor: pointer;
				height: 2
			}
		}
		.video-cropper {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			height: 100%;
			padding-left: 177.78%
		}
		iframe {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100%;
		}
	}
}

.payoff {
	padding: 100px 0;
	text-align: center;
	background: #f9f9f9 url("../img/texture.png") center center repeat;
	position: relative;
	z-index: -1;
	&:after {
		@include pseudo;
		bottom: 0;
		width: 100%;
		height: 10%;
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
		background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
		/* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
		/* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
		/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
		/* IE6-9 */
	}
	&:before {
		@include pseudo;
		top: 0;
		width: 100%;
		height: 10%;
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100 */
		background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
		/* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
		/* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
		/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=0);
		/* IE6-9 */
	}
	.wrapper {
		overflow-x: initial;
	}
	h3 {
		font-size: 28px;
		font-weight: 400;
		line-height: 36px;
		margin: 1em 0 2em;
		@include m-down {
			font-size: 22px;
			line-height: 30px;
		}
		@include s-down {
			font-size: 18px;
			line-height: 24px;
		}
		@include xs-down {
			font-size: 16px;
			line-height: 20px;
		}
	}
	@include s-down {
		padding: 50px 0;
	}
	@include xs-down {
		padding: 40px 0;
	}
	@include xxl-up {
		padding: 6.25vw 0;
		h2 {
			font-size: 2.25vw;
			line-height: 2.8125vw;
		}
		h3 {
			font-size: 1.75vw;
			line-height: 2.375vw;
		}
	}
	.button-container {
		display: inline-block;
		margin: 10px 0 0;
		position: relative;
		line-height: 1;
		a.button {
			margin: 0 10px 0;
		}
		span.counter {
			position: absolute;
			display: block;
			top: 0;
			left: auto;
			right: 10px;
			height: 25px;
			width: 25px;
			background-color: #f44336;
			line-height: 25px;
			text-align: center;
			border-radius: 50%;
			z-index: 99;
			transform: translateY(-33%);
			color: white;
			font-weight: 600;
			font-size: 14px;
		}
	}
	&-servizi {
		background: none;
		padding: 100px 0 40px;
		@include s-down {
			padding: 50px 0 20px;
		}
		@include xs-down {
			padding: 40px 0 20px;
		}
		@include xxl-up {
			padding: 6.25vw 0 2.5vw;
		}
	}
}

.wrapper.wrapper-servizi {
	padding-top: 20px;
}

.servizio {
	height: 425px;
	margin-bottom: 70px;
	@include clearfix;
	@include s-down {
		height: auto;
		margin-bottom: 50px;
	}
	.text-container {
		h2 {
			font-size: 34px;
			font-weight: 500;
			line-height: 36px;
			@include xxl-up {
				font-size: 2.125vw;
				line-height: 2.5vw;
			}
			@include s-down {
				font-size: 30px;
				line-height: 33px;
			}
		}
		p {
			color: $light-grey;
			font-size: 16px;
			font-weight: 400;
			line-height: 22px;
			margin-bottom: 50px;
			@include xxl-up {
				font-size: 1vw;
				line-height: 1.375vw;
				margin-bottom: 3.125vw;
			}
			@include s-down {
				margin-bottom: 20px;
			}
		}
	}
	.img-container {
		height: 100%;
		background: url("../img/bg1.jpg") center center;
		background-size: cover;
		box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3);
		position: relative;
		@include s-down {
			height: 300px;
		}
		.blue-overlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: $main-blue
		}
	}
	&.img-destra {
		.text-container {
			padding: 68px 40px 0 0;
			@include inwrapper(left);
			@include xxl-up {
				padding: 4.25vw 2.5vw 0 0;
			}
			@include s-down {
				padding: 20px 0;
			}
		}
		.img-container {
			@include outwrapper(right);
			.blue-overlay {
				transform-origin: center left;
			}
		}
	}
	&.img-sinistra {
		.text-container {
			padding: 68px 0 0 40px;
			@include inwrapper(right);
			@include xxl-up {
				padding: 4.25vw 0 0 2.5vw;
			}
			@include s-down {
				padding: 30px 0;
			}
		}
		.img-container {
			@include outwrapper(left);
			.blue-overlay {
				transform-origin: center right;
			}
		}
	}
	@include xxl-up {
		height: 26.5625vw;
		margin-bottom: 4.375vw;
	}
}

#scrollLockWrap {
	width: 100%;
	height: calc(100% + 2400px);
	position: relative;
	pointer-events: none;
}

#scrollLockCont {
	position: absolute;
	height: 100vh;
	width: 100%;
	top: 0;
	text-align: center;
	overflow: hidden;
	padding-top: 30px;
	@include xxl-up {
		padding-top: 1.875vw;
	}
	&.fixed {
		position: fixed;
	}
	&.fixed-bottom {
		position: absolute;
		bottom: 0;
		top: auto;
	}
	@include s-down {
		padding-top: 110px;
	}
	.logo-center {
		margin: 0 auto;
		width: 400px;
		position: relative;
		@include xxl-up {
			width: 25vw;
		}
		@include xs-down {
			width: 85%;
			max-width: 300px;
		}
	}
	#livello1 {
		display: block;
		margin: 0 auto;
		transform: rotate(-7deg);
		path {
			stroke: $main-blue;
			stroke-dasharray: 265px;
			stroke-dashoffset: 265px;
		}
	}
	#livello2 {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		margin: 0 auto;
		z-index: -1;
		transform: rotate(-7deg);
		path {
			stroke: rgba(#bfe7f7, .5);
		}
	}
	h4 {
		text-transform: uppercase;
		color: $main-blue;
		font-weight: 400;
		margin-bottom: 0;
		white-space: nowrap;
		@include s-down {
			font-size: 14px;
		}
	}
	.sliding-text-container {
		width: 800%;
		transform: translateY(12.5%);
		@include clearfix;
		h2 {
			width: 12.5%;
			float: left;
			font-size: 34px;
			color: $light-grey;
			font-weight: 300;
			line-height: 40px;
			text-align: center;
			margin: 0;
			@include xxl-up {
				font-size: 2.125vw;
				line-height: 2.5vw;
			}
			@include s-down {
				padding: 0px;
				margin: 0;
			}
			@include xs-down {
				font-size: 18px;
				line-height: 22px;
			}
		}
	}
}

section.launch {
	text-align: center;
	padding: 20px 24% 22px;
	color: $main-blue;
	@include xxl-up {
		padding: 2.5vw 20% 5vw;
	}
	@include m-down {
		padding: 20px 30px;
	}
	h2 {
		font-weight: 500;
		font-size: 24px;
		line-height: 27px;
		margin: 0;
		@include xs-down {
			font-size: 20px;
			margin: 0
		}
	}
	p {
		font-size: 18px;
		line-height: 23px;
		@include xxl-up {
			font-size: 1.25vw;
			line-height: 1.75vw;
		}
		@include xs-down {
			font-size: 14px;
			line-height: 20px;
		}
	}
	.info-box {
		display: inline-block;
		@include s-down {
			display: block;
		}
		&+.info-box {
			margin-left: 40px;
			@include xxl-up {
				margin-left: 2.5vw;
			}
			@include s-down {
				margin-left: 0;
				margin-top: 8px;
			}
		}
		h4 {
			text-align: left;
			font-size: 14px;
			color: white;
			text-transform: uppercase;
			margin-top: 0;
			@include xxl-up {
				font-size: .9375vw;
			}
			@include s-down {
				text-align: center;
				font-size: 13px;
			}
		}
		h5 {
			text-align: left;
			font-size: 24px;
			font-weight: 500;
			margin: 0;
			@include xxl-up {
				font-size: 1.75vw;
			}
			@include s-down {
				text-align: center;
				font-size: 22px;
			}
			a:hover {
				border-bottom: 2px solid rgba(#fff, .4)
			}
		}
	}
	&.azzurro {
		background-color: #BFE7F7;
		a.button {
			&:before {
				background-color: transparent;
			}
			&:hover {
				color: $main-blue;
				border-color: $main-blue;
			}
			&:after {
				background-color: white;
			}
		}
	}
	&.celeste {
		background-color: #7FCEEF;
		a.button {
			background-color: #7FCEEF;
			&:after {
				background-color: #7FCEEF;
			}
		}
	}
	&.blu {
		h2 {
			color: white;
		}
		color: white;
		background-color: #009EE0;
		a.button {
			border-color: white;
			color: white;
			&:before {
				background-color: transparent;
			}
			&:after {
				background-color: white;
			}
			&:hover {
				border-color: $main-blue;
				color: $main-blue;
			}
		}
	}
	a.button {
		font-size: 15px;
		min-width: 180px;
		font-weight: 500;
		height: 36px;
		line-height: 34px;
	}
}



.cookie-notice-container {
	#cn-accept-cookie {
		min-width: 80px;
		vertical-align: middle;
	}
	#cn-more-info {
		height: auto;
		min-width: 0;
		display: inline;
		padding: 0;
		border: none;
		background-color: transparent;
		color: white;
		text-decoration: underline;
		line-height: inherit;
		&:before, &:after {
			background-color: transparent;
		}
	}
}